import {
  type SagaReturnType,
  takeEvery, cancel, put, delay,
} from 'redux-saga/effects';
import moment from 'moment-timezone';
import type { NoteType } from 'app/entities';

import { saveLastRoute, stripTags } from 'utils';
import * as api from 'services/api';
import { navigate } from 'navigation/methods';
import { call } from 'store/utils/saga/effects';

import Alert from 'components/Alert';
import network from 'lib/network';
import * as materialStore from 'store/nodes/content';
import * as collectionStore from 'store/nodes/playlist';
import { actions, type ResourceType } from '../slice';
import * as utils from '../utils';

export const config = {
  action: actions.add.type,
  method: takeEvery,
};

const getUrl = (
  relation?: { resourceType: ResourceType, resourceId: number },
) => {
  if (!relation || !relation?.resourceType) {
    return '/note/upload';
  }
  if (relation.resourceType === 'material') {
    return `/content/${relation.resourceId}/note`;
  }
  if (relation.resourceType === 'collection') {
    return `/playlists/${relation.resourceId}/note`;
  }
  return null;
};

// @todo проверить почему вызов create происходит только 1 раз при условии наличия лимита.
export function* func(action: SagaReturnType<typeof actions.add>) {
  const {
    html, relation,
  } = action.payload;

  const hasSession = yield* call(() => api.credentials.hasSession());
  if (!hasSession) {
    yield* call(() => saveLastRoute());
    yield* call(() => navigate('Auth/Start'));
    yield cancel(); return;
  }

  const url = yield* call(() => getUrl(relation));
  if (!url) {
    yield put(actions.addDone(relation ? { relation } : undefined));
    yield cancel(); return;
  }

  const { data, hasError, errors } = yield* call(() => (
    network.request<NoteType | { errors: { type: string, message: string }}>(url).body({ text: html, plain_text: stripTags(html) }).post()
  ));

  if (!data || data && 'errors' in data) {
    Alert.error(data?.errors.message || 'Failed to create document');
    yield put(actions.addDone(relation ? { relation } : undefined));
    yield cancel(); return;
  }

  const preparedData = yield* utils.prepareNote({ ...data, createdAt: moment().toISOString() });
  const item = {
    data: preparedData,
    ...(relation && {
      relation: {
        resourceType: relation.resourceType,
        resourceId: relation.resourceId,
      },
    }),
  };

  yield put(actions.setItem(item));
  yield put(actions.addDone(relation ? { relation } : { id: preparedData.id }));

  yield delay(100);

  if (!relation) {
    yield* call(() => navigate('Note', { id: preparedData.id }, { replace: true }));
    // yield* call(() => history.replaceState(null, '', `/note/${preparedData.id}`));
  }
  if (relation?.resourceType === 'material') {
    yield put(materialStore.actions.loadById(relation.resourceId));
  }
  if (relation?.resourceType === 'collection') {
    yield put(collectionStore.actions.loadById({ id: relation.resourceId }));
  }
}
