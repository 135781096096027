import {
  type FC, memo, useCallback, useState,
} from 'react';
import { createUseStyles } from 'react-jss';
import {
  Box,
  IconButton,
  Tooltip,
  useTheme,
} from '@mui/joy';
import cn from 'classnames';

import CopilotInput from 'widgets/Copilot/ui/CopilotInput';

import Icon from 'ui/Icon';

type CopilotInputConnectorProps = {
  className?: string,
  disabled?: boolean,
  isAnswerTyping?: boolean,
  onSubmit?: (value: string) => void;
  onStop?: () => void;
};

const CopilotInputConnector: FC<CopilotInputConnectorProps> = (props) => {
  const {
    className,
    disabled,
    isAnswerTyping,
    onSubmit,
    onStop,
  } = props;

  const theme = useTheme();

  const [inputValue, setInputValue] = useState('');

  const classes = useStyle({
    backgroundColor: theme.palette.background.level1,
  });

  const handleSubmit = useCallback(() => {
    onSubmit?.(inputValue);
    setInputValue('');
  }, [onSubmit, inputValue]);

  return (
    <CopilotInput
      disabled={disabled}
      autoFocus
      value={inputValue}
      onSubmit={handleSubmit}
      onChange={setInputValue}
      className={cn(classes.input, className)}
      endAdornment={(
        <Box display="flex" pr={1} alignSelf="flex-end" mb={0.75}>
          {!isAnswerTyping && (
            <Tooltip arrow variant="outlined" size="sm" title="Send">
              <IconButton color="neutral" onClick={handleSubmit} disabled={!inputValue}>
                <Icon name="circle-arrow-up" fw weight="solid" color="inherit" />
              </IconButton>
            </Tooltip>
          )}
          {isAnswerTyping && (
            <Tooltip arrow variant="outlined" size="sm" title="Stop">
              <IconButton color="neutral" onClick={onStop}>
                <Icon name="stop" fw weight="solid" color="inherit" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      )}
    />
  );
};

const useStyle = createUseStyles<
  | 'input',
  {
    backgroundColor?: string,
  }
>({
  input: {
    flex: 1,
  },
});

export default memo(CopilotInputConnector);
