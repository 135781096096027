import {
  type SagaReturnType,
  put, takeLatest, delay,
} from 'redux-saga/effects';

import * as appStore from 'store/nodes/app';
import * as authStore from 'store/nodes/auth';
import * as subscriptionSocketActions from 'widgets/Subscription/store/socketActions';
import { actions } from '../slice';

export const config = {
  action: [
    appStore.actions.prepared.type,
    authStore.actions.logOutDone.type,
    authStore.actions.doSignInDone.type,
    subscriptionSocketActions.customerSubscriptionUpdated.type,
    subscriptionSocketActions.customerSubscriptionDeleted.type,
    subscriptionSocketActions.subscriptionPaymentIntentSucceeded.type,
  ],
  method: takeLatest,
};

export function* func(action: SagaReturnType<
  | typeof appStore.actions.prepared
  | typeof authStore.actions.doSignInDone
  | typeof authStore.actions.logOutDone
>) {
  yield delay(50);
  yield put(actions.loadAvailableModels());
  yield put(actions.loadSelectedModel());
}
