import { memo } from 'react';
import {
  Box, Typography,
} from '@mui/joy';

import { useScreenSize } from 'hooks';

import { CopilotScopeSwitcher } from 'widgets/Copilot';

const TopBarDesktop = () => {
  const screenSize = useScreenSize();

  if (screenSize.is767) {
    return null;
  }

  return (
    <Box display="flex" flex={1} zIndex={10}>
      <Box display="flex" flexDirection="row" flex={1} alignItems="flex-end" mt={-2}>
        <Box display="flex" flex={1} flexDirection="column" ml={5} mt={2} gap={1}>
          <Typography fontSize={17}>Ask in:</Typography>
          <CopilotScopeSwitcher />
        </Box>
      </Box>
    </Box>
  );
};

export default memo(TopBarDesktop);
