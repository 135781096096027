import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type RouteType } from 'navigation/methods/getRoute';

export type NavigationStore = {
  pathname: [null, string] | string[],
}

const initialState: NavigationStore = {
  pathname: [],
};

const noteSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    locationChange(state) {
      const url = new URL(location.href);
      if (state.pathname.length === 0) {
        state.pathname = [null, url.pathname];
        return;
      }
      state.pathname.push(url.pathname);
      if (state.pathname.length > 2) {
        state.pathname.shift();
      }
    },
    route: () => undefined,
    routeDone: () => undefined,
    redirectBefore: (_, action: PayloadAction<{ route: RouteType<any> }>) => undefined,
    redirectAfter: (_, action: PayloadAction<{ route: RouteType<any> }>) => undefined,
    runPageHandler() {
    },
  },
});

export const {
  reducer,
  actions,
} = noteSlice;
