import { memo, useEffect, useRef } from 'react';
import { Typography, Box, useTheme } from '@mui/joy';

import { useSelector } from 'store/utils/redux/hooks';
import * as userStore from 'store/nodes/user';
import * as copilotStore from 'widgets/Copilot/store';
import type {
  ItemAttribute, ItemId, QueryType,
} from 'widgets/Copilot/store/types';

import Avatar from 'ui/Avatar';

type QuestionProps = {
  id: ItemId,
  attribute: ItemAttribute,
  onMount?: () => void,
}

const Question = (props: QuestionProps) => {
  const {
    id,
    attribute,
    onMount,
  } = props;

  const theme = useTheme();

  const mountRef = useRef(false);
  const userProfile = useSelector(userStore.selectors.getMy);
  const data = useSelector(copilotStore.selectors.dataById<QueryType>(id));

  useEffect(() => {
    if (!mountRef.current) {
      mountRef.current = true;
      onMount?.();
    }
  }, [onMount]);

  return (
    <Box
      display="flex"
      flexDirection="row"
      borderRadius="1em"
      padding="1em"
      width="85%"
      alignSelf="flex-end"
      gap="1em"
      bgcolor={theme.palette.mode === 'dark' ? theme.palette.neutral['200'] : theme.palette.neutral['700']}
    >
      <Avatar src={userProfile?.photo?.url} thumbnail="small" sx={{ '--Avatar-size': '32px' }}>
        {userProfile?.name?.[0] || 'G'}
      </Avatar>
      <Typography sx={{ color: theme.palette.mode === 'dark' ? theme.palette.neutral['600'] : theme.palette.neutral['300'] }}>{data.payload.label || data.payload.text}</Typography>
    </Box>
  );
};

export default memo(Question);
