import { useMemo } from 'react';

import { useSelector } from 'store/utils/redux/hooks';
import * as subscriptionStore from '../store';

import useSubscriptionConfig from './useSubscriptionConfig';

const useBarLabels = () => {
  const { bar } = useSubscriptionConfig();
  const currentPlan = useSelector(subscriptionStore.selectors.currentPlan);

  const { label: stateLabel = 'Checking' } = bar.state.find((item) => {
    if (!currentPlan.id || !currentPlan.isLoaded) {
      return item.ids.includes('-1');
    }
    return item.ids.includes(currentPlan.id);
  }) || {};

  return useMemo(() => ({
    stateLabel,
    portal: {
      label: bar.portal.label.replace(/\[planName]/g, currentPlan.name || 'Checking'),
      isClickable: bar.portal.isClickable,
    },
  }), [stateLabel, bar.portal.label, bar.portal.isClickable]);
};

export default useBarLabels;
