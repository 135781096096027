import {
  takeLatest, put,
  type SagaReturnType,
} from 'redux-saga/effects';

import Alert from 'components/Alert';

import * as api from 'services/api';

import { call, select } from 'store/utils/saga/effects';
import * as authStore from 'store/nodes/auth';
import * as userStore from 'store/nodes/user';

import * as actions from '../actions';

export const config = {
  action: actions.deleteAccount.type,
  method: takeLatest,
};

export function* func(action: SagaReturnType<typeof actions.deleteAccount>) {
  const user = yield* select(userStore.selectors.getByLogin('my'));

  const result = yield* call(() => api.resource.profile.deleteAccount());
  if (result.error) {
    Alert.error(result?.error?.message || 'Server error #33');
  }
  localStorage.removeItem(`isTrialAsked#${user?.id}`);

  yield put(authStore.actions.logOut());
}
