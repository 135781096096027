import {
  takeEvery, put, cancel, delay, take,
} from 'redux-saga/effects';
import { track } from '@amplitude/analytics-react-native';

import { getRoute } from 'navigation/methods';

import { call, select } from 'store/utils/saga/effects';
import * as searchStore from 'store/nodes/search';
import * as materialStore from 'store/nodes/content';
import * as libraryStore from 'store/nodes/library';

import { actions } from '../slice';

export const config = {
  action: actions.runPageHandler.type,
  method: takeEvery,
};

const prepareTags = (tags?: string | string[]) => {
  if (Array.isArray(tags) && tags.length > 0) {
    return [...new Set(tags)];
  }
  if (typeof tags === 'string' && tags.length > 0) {
    return [tags];
  }
  return [];
};

export function* func() {
  const route = yield* call(() => getRoute<'Ask'>());
  if (!route.isScreen('Ask')) {
    yield cancel(); return;
  }

  const tags = yield* call(() => prepareTags(route.params?.tags));
  const currentTags = yield* select(searchStore.selectors.filterTags);
  if (JSON.stringify(currentTags) !== JSON.stringify(tags)) {
    yield put(searchStore.actions.setMethod({ value: 'GlobalFullText', triggerReload: false }));
    yield put(searchStore.actions.setFilterTags(tags));
  }

  const meta = yield* select((state) => ({
    isMaterialTypeFiltersLoaded: state.content.list.meta.isFiltersLoaded,
    isLibraryTypeFiltersLoaded: state.library.filter.meta.isLoaded,
  }));
  if (!meta.isMaterialTypeFiltersLoaded) {
    yield put(materialStore.actions.loadFilters());
    yield take(materialStore.actions.loadFiltersDone.type);
    yield delay(5);
  }
  if (!meta.isLibraryTypeFiltersLoaded) {
    yield put(libraryStore.actions.loadFilters());
    yield take(libraryStore.actions.loadFiltersDone.type);
    yield delay(5);
  }

  yield* call(() => track('Ask'));
}
