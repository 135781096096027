import {
  type SagaReturnType,
  put, cancel, delay, takeEvery,
} from 'redux-saga/effects';

import type {
  MaterialType,
  CollectionType, RagMaterialType,
} from 'app/entities';
import Alert from 'components/Alert';
import { track } from '@amplitude/analytics-react-native';
import * as uuid from 'uuid';

import * as api from 'services/api';

import { guard, getQueryByFilterList } from 'utils';

import { call, select } from 'store/utils/saga/effects';
import { checkUnauthorized } from 'store/utils/credentials';
import * as materialStore from 'store/nodes/content';
import * as searchStore from 'store/nodes/search';
import * as collectionStore from 'store/nodes/playlist';
import * as libraryStore from 'store/nodes/library';
import * as userStore from 'store/nodes/user';
import * as copilotStore from 'widgets/Copilot/store';

import * as actions from '../actions';
import * as selectors from '../selectors';

export const config = {
  action: actions.loadNextPage.type,
  method: takeEvery,
};

function* getFilters() {
  let filters: Record<string, any> = {};

  const text = yield* select(selectors.filterText);
  const method = yield* select(selectors.method);
  const filterResourceTypeValue = yield* select(selectors.filterResourceTypeValue);

  if (text) {
    filters.q = text;
  }

  const tags = yield* select(selectors.filterTags);
  const [type] = filterResourceTypeValue;
  filters = {
    ...filters,
    tags,
    type,
  };

  if (/Library/i.test(method)) {
    const panelTypes = yield* select(libraryStore.selectors.panelTypes);
    filters = {
      ...filters,
      ...getQueryByFilterList(panelTypes.filter((item) => filterResourceTypeValue.includes(item.id))),
    };
  }

  return filters;
}

function* trackSearchEvent(text: string) {
  const userProfile = yield* select(userStore.selectors.getMy);
  const method = yield* select(selectors.method);
  const searchTypeToEventMap = {
    GlobalFullText: 'Ask:Global:Fulltext',
    GlobalTitle: 'Ask:Global:Title',
    LibraryFullText: 'Ask:Library:Fulltext',
    LibraryTitle: 'Ask:Library:Title',
    Web: 'Ask:Web',
  };
  const event = searchTypeToEventMap[method];

  yield* call(() => track(event, { userId: userProfile?.id, userName: userProfile?.name, text }));
}

export function* func(action: SagaReturnType<typeof actions.loadNextPage>) {
  const { reload } = action.payload;

  const text = yield* select(selectors.filterText);
  const method = yield* select(selectors.method);
  const userProfile = yield* select(userStore.selectors.getByLogin('my'));

  const nextCursor = yield* select(selectors.selectLoadNextCursor(reload));

  const pagination = {
    cursor: nextCursor || undefined,
    pageSize: userProfile?.type === 'legal' ? 12 : 24,
  };

  const filters = yield* getFilters();

  if (reload) {
    yield* trackSearchEvent(text);
  }

  const extraParams: Record<string, number | string> = {};
  if (reload && (method === 'Web' || method === 'GlobalFullText' || method === 'LibraryFullText')) {
    yield put(copilotStore.actions.clear());
    yield delay(10);
    const requestId = uuid.v4();
    extraParams.request_id = requestId;
    yield put(copilotStore.actions.answerStart({
      requestId,
      resourceId: 0,
      resourceType: {
        'Web': 'web' as const,
        'LibraryFullText': 'library-fulltext' as const,
        'GlobalFullText': 'global-fulltext' as const,
      }[method],
    }));
  }

  const response = yield* call(() => (
    api.resource.content.search.items(method, pagination, filters, extraParams)
  ));
  yield checkUnauthorized(response);

  if (response.error || !response.data) {
    Alert.error(response?.error?.message || 'Server error #18');
    yield put(actions.loadNextPageDone(reload, 'error'));
    yield cancel(); return;
  }

  const materials: MaterialType[] = [];
  const collections: CollectionType[] = [];
  const ragMaterial: RagMaterialType[] = [];
  response.data.items.forEach((item) => {
    if (guard.isMaterial(item.element)) {
      materials.push(item.element);
    }
    if (guard.isCollection(item.element)) {
      collections.push(item.element);
    }
    if (guard.isRagMaterial(item.element)) {
      ragMaterial.push(item.element);
    }
  });

  if (materials.length > 0) {
    yield put(materialStore.actions.setItem(materials));
  }
  if (collections.length > 0) {
    yield put(collectionStore.actions.setItem({ data: collections }));
  }

  if (ragMaterial.length > 0) {
    yield put(searchStore.actions.setRagMaterial(ragMaterial));
  }

  yield delay(10);
  yield put(actions.addPage(response.data.items, response.data.paginationInfo, reload));
  let newStatus: 'has-results' | 'no-results' | 'nothing-to-search' = 'has-results';
  if (response.data.items.length === 0 && response.data.metadata?.isMaterialsExists === false) {
    newStatus = 'nothing-to-search';
  } else if (response.data.items.length === 0) {
    newStatus = 'no-results';
  }
  yield put(actions.loadNextPageDone(reload, newStatus));
}
