import { type DefaultRootState } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';

import type { UserType } from 'app/entities';
import * as api from 'services/api';

const selector = createSelector(
  (state: DefaultRootState) => state.user.data[state.user.my?.login],
  () => api.credentials.hasSession(),
  (data, hasSession) => {
    if (!data) {
      return null;
    }
    return ({
      ...data,
      isRegisterCompleted: hasSession && data.isRegisterCompleted,
    });
  },
);

export const getMy = (state: DefaultRootState): UserType | null => {
  if (!state.user?.my) {
    return null;
  }
  return selector(state);
};
