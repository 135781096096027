import { useMemo } from 'react';

import useSubscriptionConfig from './useSubscriptionConfig';
import type { PeriodType, PlanDataType } from '../types';

const usePlans = (period: PeriodType): PlanDataType[] => {
  const { periods, plans } = useSubscriptionConfig();

  return useMemo(() => {
    const currentPeriod = periods.find((item) => item.id === period);
    if (!currentPeriod || !Array.isArray(currentPeriod.planIds) || currentPeriod.planIds.length === 0) {
      return [];
    }
    return plans.filter((item) => currentPeriod.planIds.includes(item.identity.planId)).map((item) => {
      const label = { ...item.label };
      (Object.keys(label) as (keyof typeof label)[]).forEach((key) => {
        label[key] = label[key].replace(/\[period]/g, period);
      });
      return ({
        ...item,
        label,
      });
    });
  }, [period, periods, plans]);
};

export default usePlans;
