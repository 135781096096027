import {
  memo, useEffect, useMemo,
} from 'react';
import { Box } from '@mui/joy';

import { useSelector } from 'store/utils/redux/hooks';
import * as askStore from 'store/nodes/ask';

import AdaptivePage from 'components/AdaptivePage';
import { useScrollMethods } from 'components/Scroller';
import AddNoteButton from 'components/AddNoteButton';
import PlusButton from 'components/PlusButton';
import TutorialStep from 'components/TutorialStep';

import Copilot, {
  CopilotUtilityBar, CopilotModelSelect, CopilotResetButton, CopilotShowToggle,
} from 'widgets/Copilot';
import PanelsBar from 'widgets/PanelsBar';

import SearchResult from './components/SearchResult';

import TopBarDesktop from './ui/TopBarDesktop';
import TopBarMobile from './ui/TopBarMobile';

const Regular = () => {
  const { scrollToEnd } = useScrollMethods();
  const scope = useSelector(askStore.selectors.scope);

  useEffect(() => {
    setTimeout(() => {
      scrollToEnd();
    }, 0);
  }, []);

  const pagePaddingHorizontalScheme = useMemo(() => ({
    320: 16,
  }), []);

  const contentMeta = useMemo(() => ({
    label: 'Ask',
    value: 'ask',
  }), []);

  const contentRender = useMemo(() => (
    <>
      {scope === 'web' && <Copilot variant="web" windowScroll />}
      {scope === 'library-fulltext' && <Copilot variant="library-fulltext" windowScroll />}
      {scope === 'global-fulltext' && <Copilot variant="global-fulltext" windowScroll />}
    </>
  ), [scope]);

  const panelRender = useMemo(() => [
    {
      label: 'Found materials',
      value: 'copilot',
      component: <SearchResult />,
    },
  ], []);

  return (
    <AdaptivePage
      desktopHeaderLeftPanel={(
        <>
          <TopBarDesktop />
          <TopBarMobile />
        </>
      )}
      desktopHeaderMiddlePanel={(
        <Box display="flex" flexDirection="row" alignSelf="flex-end" gap={1}>
          <CopilotResetButton resources={[{ type: 'web' }, { type: 'library-fulltext' }, { type: 'global-fulltext' }]} />
          <CopilotShowToggle mr={-1} />
        </Box>
      )}
      desktopHeaderRightPanel={(
        <CopilotUtilityBar
          pl={1}
          left={(
            <CopilotUtilityBar.Item canHidden><CopilotModelSelect ml={2} /></CopilotUtilityBar.Item>
          )}
          right={(
            <>
              <AddNoteButton />
              <TutorialStep stepIndex={1} title="Add to library" description="Save any urls, drop pdfs or enter notes here">
                <PlusButton />
              </TutorialStep>
            </>
          )}
        />
      )}
      bodyMaxWidth={1280}
      paddingHorizontalScheme={pagePaddingHorizontalScheme}
    >
      <Box sx={{ '@media (max-width: 767px)': { mt: 5 } }}>
        <PanelsBar
          contentMeta={contentMeta}
          content={contentRender}
          panels={panelRender}
          sx={{
            '@media (max-width: 767px)': {
              mx: -2,
            },
          }}
        />
      </Box>
    </AdaptivePage>
  );
};

export default memo(Regular);
