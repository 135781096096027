import {
  takeLatest, put, cancel, take, delay,
} from 'redux-saga/effects';
import type { SagaReturnType } from 'redux-saga/effects';
import { track } from '@amplitude/analytics-react-native';

import Alert from 'components/Alert';

import * as api from 'services/api';

import { call } from 'store/utils/saga/effects';
import * as userStore from 'store/nodes/user';
import * as navigationStore from 'store/nodes/navigation';

import * as actions from '../actions';

export const config = {
  action: actions.doSignIn.type,
  method: takeLatest,
};

export function* func(action: SagaReturnType<typeof actions.doSignIn>) {
  const { email, password } = action.payload;

  const { data, error: errors } = yield* call(() => api.resource.auth.login(email, password));
  const error = Array.isArray(errors) ? errors[0] : errors;
  if (error || !data) {
    Alert.error(error?.message || 'Server error #5');
    yield put(actions.doSignInDone(error || 'error_login'));
    yield cancel(); return;
  }

  const { access, refresh } = data;
  if (!access || !refresh) {
    yield put(actions.doSignInDone('error_login'));
    yield cancel(); return;
  }

  yield* call(() => api.credentials.set(access, refresh));

  yield put(userStore.actions.loadProfile());
  yield take(userStore.actions.loadProfileDone.type);
  yield delay(10);

  yield put(navigationStore.actions.route());
  yield take(navigationStore.actions.routeDone.type);

  yield put(actions.doSignInDone());
  yield put(actions.loginSuccess());

  yield* call(() => track('Login'));
}
