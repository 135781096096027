import {
  type CSSProperties,
  memo, useMemo,
} from 'react';
import {
  type StyleProp, type ViewStyle,
  StyleSheet, TouchableOpacity, Platform,
} from 'react-native';
import { Typography } from '@mui/joy';

import { guard, unit } from 'utils';
import { useMaterialInfo } from 'hooks/material';

import { Text, View } from 'components/Themed';
import WebLink from 'components/WebLink';
import Picture from 'components/Picture';
import Info from 'components/Info';

import { useColorScheme } from 'hooks';
import Tags from 'components/Tags';

import {
  useAuthor, useTagsCrop,
} from '../hooks';

import FooterBlock from '../../../elements/FooterBlock';
import AuthorBlock from '../../../elements/AuthorBlock';
import HeaderToolbar from '../../../elements/HeaderToolbar';
import NoteBlock from '../elements/NoteBlock';

import type { MaterialLayoutProps } from '../types';

const defaultProps = {
  hasToolbar: true,
  hasAuthor: true,
  hasInfo: true,
  hasTags: true,
  hasComments: true,
  hasFooterQueue: false,
};

const NoteLayout = (props: MaterialLayoutProps) => {
  const {
    data,
    hasMarkByIKI,
    hasToolbar,
    hasAuthor,
    hasInfo,
    hasTags,
    hasComments,
    hasWebLink,
    hasFooterQueue,
    tagsLimit,
    AuthorComponent,
    ExtraComponent,
    OverlayComponent,
    isHoverMode,
    isTouchMode,
    onAuthorPress,
    onBodyPress,
    onOptionsPress,
    onSourcePress,
    onCommentsPress,
    onBookmarkPress,
  } = props;

  const theme = useColorScheme();

  const tags = useTagsCrop(data?.tags, tagsLimit);
  const info = useMaterialInfo(data, hasMarkByIKI);
  const authorData = useAuthor(data?.authors?.[0]);

  const panelStyle = useMemo((): StyleProp<ViewStyle> => [
    styles.panel,
    {
      borderRadius: unit(6),
    },
  ], []);

  const noteStyle = useMemo(() => {
    const result: StyleProp<ViewStyle> = {
      ...StyleSheet.flatten(styles.note),
      opacity: isHoverMode ? 0.5 : 1,
    };
    if (isTouchMode) {
      result.opacity = 1;
    }
    if (Platform.OS === 'web') {
      (result as CSSProperties).transition = 'opacity 300ms ease';
    }
    return result;
  }, [isHoverMode]);

  const toolbarStyle = useMemo(() => {
    const result: StyleProp<ViewStyle> = {
      ...StyleSheet.flatten(styles.toolbar),
      opacity: isHoverMode ? 1 : 0,
    };
    if (isTouchMode) {
      result.opacity = 1;
    }
    if (Platform.OS === 'web') {
      (result as CSSProperties).transition = 'opacity 300ms ease';
    }
    return result;
  }, [isHoverMode, isTouchMode]);

  const containerStyle = useMemo(() => {
    const result: StyleProp<ViewStyle> | CSSProperties = ({
      ...StyleSheet.flatten(styles.NoteLayout),
    });
    if (guard.isWebStyleProp(result) && theme === 'light') {
      result.boxShadow = '0 0 2px rgba(0, 0, 0, 0.25), 0 2px 5px rgba(0, 0, 0, 0.15)';
    }
    return result as StyleProp<ViewStyle>;
  }, [theme]);

  if (!data) {
    return (
      <View style={styles.wireframe} lightColor="#ffffff50" darkColor="#ffffff20" />
    );
  }

  return (
    <TouchableOpacity
      style={containerStyle}
      onPress={onBodyPress}
      activeOpacity={guard.isLink(data?.originalUrl) ? 0.8 : 1}
    >
      {!!OverlayComponent && (
        <View pointerEvents="none" style={styles.overlay}>{OverlayComponent}</View>
      )}
      <View style={panelStyle} darkColor="#202020" lightColor="#ffffff">
        <WebLink href={data?.internalUrl} disabled={!hasWebLink}>
          <NoteBlock text={data?.note?.text} style={noteStyle} />
          {hasTags && tags?.length > 0 && (
            <Tags data={data} tagsLimit={tagsLimit} sx={{ marginBottom: unit(10) }} />
          )}
          {hasAuthor && !!authorData && (
            <AuthorBlock onPress={onAuthorPress} Component={AuthorComponent} data={authorData} style={{ marginBottom: 11 }} />
          )}
          <View style={styles.material}>
            {!!data?.image && (
              <Picture source={data?.image} size="medium" hasBackground style={styles.poster} aspectRatio={1} radius={3} />
            )}
            <View style={styles.text}>
              <Typography fontSize={16} fontWeight="bolder">{data?.title?.trim?.()}</Typography>
              {hasInfo && !!info && (
                <Info list={info} fontSize={13} />
              )}
            </View>
          </View>
          {!!ExtraComponent && ExtraComponent}
          {(isTouchMode || hasComments || hasFooterQueue) && (
            <FooterBlock
              style={styles.footer}
              data={data}
              hasOptions={isTouchMode}
              hasQueue={hasFooterQueue}
              MainComponent={hasComments ? undefined : false}
              onCommentsPress={onCommentsPress}
              onOptionsPress={onOptionsPress}
              onBookmarkPress={onBookmarkPress}
            />
          )}
        </WebLink>
      </View>
      {hasToolbar && !!data && !isTouchMode && (
        <HeaderToolbar
          style={toolbarStyle}
          sourceName={data?.source?.name}
          align="right"
          hasOptions
          hasSource={guard.isLink(data?.originalUrl) && !isTouchMode}
          onOptionsPress={onOptionsPress}
          onSourcePress={onSourcePress}
        />
      )}
    </TouchableOpacity>
  );
};

NoteLayout.defaultProps = defaultProps;

const styles = StyleSheet.create({
  NoteLayout: {
    position: 'relative',
    zIndex: Platform.OS === 'web' ? 'unset' as unknown as number : 0,
    borderRadius: unit(6),
  },
  wireframe: {
    width: '100%',
    aspectRatio: 6 / 7,
    borderRadius: unit(6),
  },
  overlay: {
    zIndex: 10,
  },
  panel: {
    borderRadius: unit(6),
    paddingHorizontal: unit(20),
    paddingTop: unit(20),
    paddingBottom: unit(10),
  },
  poster: {
    width: unit(56),
    height: unit(56),
    marginRight: unit(10),
  },
  note: {
    marginBottom: unit(11),
  },
  material: {
    flexDirection: 'row',
    marginBottom: unit(12),
  },
  text: {
    flex: 1,
    marginTop: unit(-3),
  },
  footer: {
    marginTop: unit(-2),
    marginBottom: unit(10),
  },
  toolbar: {
    position: 'absolute',
    top: unit(10),
    right: unit(16),
    left: unit(16),
    zIndex: 2,
  },
});

export default memo(NoteLayout);
