import {
  type SagaReturnType,
  cancel, takeEvery, put,
} from 'redux-saga/effects';

import { getRoute } from 'navigation/methods';

import { call } from 'store/utils/saga/effects';
import * as copilotStore from 'widgets/Copilot/store';

import { actions } from '../slice';

export const config = {
  action: actions.setSelectedCopilotContext.type,
  method: takeEvery,
};

export function* func(
  action: SagaReturnType<typeof actions.setSelectedCopilotContext>,
) {
  const route = yield* call(() => getRoute<'Note'>());
  const id = Number(route.params.id);
  if (!route.isScreen('Note') || Number.isNaN(id)) {
    yield cancel(); return;
  }
  yield put(copilotStore.actions.clear({
    resources: [
      { type: 'library-fulltext' },
      { type: 'note', id },
    ],
  }));
}
