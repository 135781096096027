import {
  type FC, type PropsWithChildren,
  memo,
} from 'react';
import { createUseStyles } from 'react-jss';
import { type SupportedColorScheme, Box, useColorScheme } from '@mui/joy';

export type TextStylesProps = {
  contentWrapperClassName?: string,
}

const TextStyles: FC<PropsWithChildren<TextStylesProps>> = (props) => {
  const {
    children,
    contentWrapperClassName,
  } = props;

  const { colorScheme } = useColorScheme();
  const classes = useStyles({
    colorScheme,
    contentWrapperClassName,
  });

  return (
    <Box component="div" className={classes.container}>
      {children}
    </Box>
  );
};

const useStyles = createUseStyles<
  | 'container',
  {
    colorScheme?: SupportedColorScheme,
    contentWrapperClassName?: string,
  }
>({
  container: (props) => ({
    [`& .${props?.contentWrapperClassName || 'wrapper'}`]: {
      '&': {
        fontSize: 16,
        lineHeight: 1.5,
        color: 'var(--joy-palette-text-primary)',
      },
      '&>*:first-child': {
        marginTop: 0,
      },
      '& h1, & h2, & h3, & h4, & h5': {
        marginTop: '1.5em',
        marginBottom: '0.5em',
      },
      '& h1': {
        fontSize: 42,
        fontWeight: 500,
        lineHeight: 1.25,
      },
      '& h2': {
        fontSize: 30,
        fontWeight: 500,
        lineHeight: 1.25,
      },
      '& h3': {
        fontSize: 24,
        fontWeight: 500,
        lineHeight: 1.3,
      },
      '& h4': {
        fontSize: 20,
        fontWeight: 500,
      },
      '& h5': {
        fontSize: 16,
        fontWeight: 500,
      },
      '& .editor-text-underline, & u': {
        textDecoration: 'underline',
      },
      '& .editor-text-strikethrough, & s': {
        textDecoration: 'line-through',
      },
      '& .editor-text-underlineStrikethrough': {
        textDecoration: 'line-through underline',
      },
      '& li': {
        marginBottom: '1em',
      },
      '& blockquote': {
        '&': {
          margin: '1em 0 0',
          paddingLeft: '1.5em',
          paddingTop: '0.25em',
          paddingBottom: '0.25em',
          borderLeft: `4px solid ${props?.colorScheme === 'light' ? '#f6edb3' : '#fff8b5'}`,
        },
        '& span': {
          fontSize: '0.75em',
          lineHeight: '1',
        },
      },
    },
  }),
});

export default memo(TextStyles);
