import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useHasSession } from 'hooks';

import * as userStore from 'store/nodes/user';

import AdaptivePage from 'components/AdaptivePage';
import AddNoteButton from 'components/AddNoteButton';
import PlusButton from 'components/PlusButton';
import { CopilotUtilityBar, CopilotShowToggle, CopilotModelSelect } from 'widgets/Copilot';

import SourceButton from './elements/SourceButton';
import ActionsBar from './elements/ActionsBar';

import LayoutDefault from './layouts/LayoutDefault';

const Content = () => {
  const hasSession = useHasSession();
  const userData = useSelector(userStore.selectors.getByLogin('my'));

  const pagePaddingHorizontalScheme = useMemo(() => ({
    320: 16,
  }), []);

  if (hasSession && !userData) {
    return null;
  }

  return (
    <AdaptivePage
      desktopHeaderLeftPanel={<ActionsBar />}
      desktopHeaderMiddlePanel={<CopilotShowToggle alignSelf="flex-end" mr={-1} />}
      desktopHeaderRightPanel={(
        <CopilotUtilityBar
          pl={1}
          left={(
            <CopilotUtilityBar.Item canHidden><CopilotModelSelect ml={2} /></CopilotUtilityBar.Item>
          )}
          right={(
            <>
              <SourceButton />
              <AddNoteButton />
              <PlusButton />
            </>
          )}
        />
      )}
      bodyMaxWidth={1280}
      paddingHorizontalScheme={pagePaddingHorizontalScheme}
      scrollToStart
    >
      <LayoutDefault />
    </AdaptivePage>
  );
};

export default Content;
