import { memo, useCallback } from 'react';
import {
  Box, Button, Sheet, Typography,
} from '@mui/joy';

import { useDispatcher, useSelector } from 'store/utils/redux/hooks';
import { selectors } from './store';

import useSubscriptionConfig from './model/useSubscriptionConfig';

const Banner = () => {
  const dispatcher = useDispatcher();
  const currentPlan = useSelector(selectors.currentPlan);
  const { banner } = useSubscriptionConfig();

  const handleUpgrade = useCallback(() => {
    dispatcher.subscription.showPricing();
  }, []);

  if (!currentPlan.isLoaded || currentPlan.id !== '0') {
    return null;
  }

  return (
    <Sheet
      variant="soft"
      sx={{
        ml: 1.125,
        mr: 1.5,
        my: 1.75,
        borderRadius: (theme) => parseFloat(theme.radius.md) * 1.25,
        p: 1.125,
      }}
    >
      <Typography color="neutral" level="body-md"><span dangerouslySetInnerHTML={{ __html: banner.title }} /></Typography>
      <Typography color="neutral" level="body-xs" mt={0.875}><span dangerouslySetInnerHTML={{ __html: banner.description }} /></Typography>
      <Box
        mt={1}
        sx={{
          '--variant-solidBg': (theme) => theme.palette.background.body,
          '--variant-solidHoverBg': (theme) => theme.palette.background.level2,
          '--variant-solidActiveBg': (theme) => theme.palette.background.level3,
        }}
      >
        <Button
          variant="solid"
          color="neutral"
          sx={{
            fontSize: 16,
            fontWeight: 300,
            borderRadius: (theme) => theme.radius.md,
            color: 'text.primary',
            py: 0.5,
            minHeight: 24,
          }}
          onClick={handleUpgrade}
        >
          {banner.buttonLabel}
        </Button>
      </Box>
    </Sheet>
  );
};

export default memo(Banner);
