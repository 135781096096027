import {
  type SagaReturnType, takeEvery, put, take,
} from 'redux-saga/effects';

import { select } from 'store/utils/saga/effects';
import * as socketStore from 'store/nodes/socket';
import * as messageStore from 'store/nodes/message';
import * as noteStore from 'store/nodes/note';

import * as actions from '../actions';

export const config = {
  action: socketStore.actions.messageInfo.type,
  method: takeEvery,
};

function* handleNoteCreated(id: number) {
  yield put(noteStore.actions.loadById({ id }));
  yield take(noteStore.actions.loadByIdDone.type);

  const data = yield* select(noteStore.selectors.dataById(id));
  if (!data) {
    return;
  }
  yield put(actions.prependToSequence('note', id));
}

export function* func(
  action: SagaReturnType<
    | typeof socketStore.actions.messageInfo
  >,
) {
  const { id, message, context } = action.payload;

  const { type: eventType, resourceType, resourceId } = context;

  if (eventType === 'library_add' && resourceType === 'note' && !!resourceId) {
    yield put(messageStore.actions.prevent({ id }));
    yield handleNoteCreated(Number(resourceId));
  }
}
