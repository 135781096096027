import {
  cancel, put, type SagaReturnType, takeEvery,
} from 'redux-saga/effects';

import { call } from 'store/utils/saga/effects';

import network from 'lib/network';
import Alert from 'components/Alert';
import {delay, openInNewTab, openWindow} from 'utils';

import { actions } from '../slice';

export const config = {
  action: [
    actions.openBillingPortal.type,
  ],
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.openBillingPortal>) {
  const { errors, data } = yield* call(() => (
    network.request<{ billingPortalSessionUrl?: string }>('/payment/billing_portal').body({
      ...(action?.payload?.hasTrial ? { trial: true } : {}),
      return_url: document.location.href,
    }).post()
  ));

  const { billingPortalSessionUrl } = data || {};

  if ((errors?.length || 0) > 0 || !billingPortalSessionUrl) {
    yield call(() => Alert.error(errors?.[0]?.message || 'Something was wrong. Aty again later.'));
    yield put(actions.openBillingPortalDone());
    yield cancel(); return;
  }

  let popupWindow: Window | null = null;

  if (action.payload?.openMode === 'popup') {
    popupWindow = yield* call(() => openWindow(billingPortalSessionUrl, 'IKI Billing'));
  } else {
    yield* call(() => openInNewTab(billingPortalSessionUrl));
  }
  yield put(actions.openBillingPortalDone());

  if (popupWindow) {
    while (!popupWindow.closed) {
      yield delay(500);
    }
    yield put(actions.closeBillingPortal());
  }
}
