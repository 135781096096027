import type { ResultLegacy, AuthLogInType } from 'app/entities';
import request from 'services/api/request';
import credentials from 'services/api/credentials';

export default async (email: string, password: string, extraData?: Record<string, string> | null): Promise<ResultLegacy<AuthLogInType>> => {
  const { data, error } = await request.post<AuthLogInType>('/auth/login', {
    body: {
      email,
      password,
      ...extraData,
    },
  });
  if (error || !data?.access || !data?.refresh) {
    await credentials.clear();
    return {
      data: null,
      error,
    };
  }
  await credentials.set(data.access, data.refresh);
  return {
    data,
    error,
  };
};
