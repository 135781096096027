import { memo, type ReactNode } from 'react';
import { Box, CardActions } from '@mui/joy';
import Tags from 'components/Tags';
import { type CollectionType, type MaterialType } from 'app/entities';

import Actions from './Actions';

import type { CardType, LegacyCardType } from '../types';

type FooterProps ={
  id: number,
  type: CardType | LegacyCardType,
  data?: CollectionType | MaterialType,
  isHover?: boolean,
  hasTags?: boolean,
  tagLimit?: number,
  startElements?: ReactNode,
  hasQueue?: boolean,
  hasToCollection?: boolean,
  hasLink?: boolean,
  hasRemove?: boolean,
  hasEdit?: boolean,
}

const Footer = (props: FooterProps) => {
  const {
    id,
    data,
    type,
    isHover,
    startElements,
    hasTags,
    hasQueue,
    hasToCollection,
    hasLink,
    hasRemove,
    hasEdit,
    tagLimit,
  } = props;

  return (
    <CardActions sx={{
      minHeight: 32,
      '--Card-padding': 0,
      justifyContent: 'space-between',
      '@container (width < 400px )': {
        flexDirection: 'column',
        gap: 1.5,
      },
      '@container (width > 400px )': {
        flexDirection: 'row',
      },
    }}
    >
      {startElements}
      {hasTags && data?.tags && data?.tags.length > 0 && (
        <Box
          sx={{
            mr: 'auto',
            '--joy-palette-background-surface': 'transparent',
            '& .MuiChip-label': {
              'font-weight': '400!important',
            },
          }}
        >
          <Tags data={data} tagsLimit={tagLimit || 10} size="md" />
        </Box>
      )}
      <Actions
        sx={{ ml: 'auto', mt: 'auto' }}
        isHover={isHover}
        resourceId={id}
        resourceType={type}
        hasQueue={hasQueue}
        hasToCollection={hasToCollection}
        hasLink={hasLink}
        hasRemove={hasRemove}
        hasEdit={hasEdit}
      />
    </CardActions>
  );
};

export default memo(Footer);
