import {
  takeEvery, put, cancel,
} from 'redux-saga/effects';
import { track } from '@amplitude/analytics-react-native';

import { getRoute } from 'navigation/methods';

import { call } from 'store/utils/saga/effects';
import * as authorStore from 'store/nodes/author';

import { actions } from '../slice';

export const config = {
  action: actions.runPageHandler.type,
  method: takeEvery,
};

export function* func() {
  const route = yield* call(() => getRoute<'Author'>());
  if (!route.isScreen('Author')) {
    yield cancel(); return;
  }

  const authorId = route.params.id;
  if (authorId) {
    yield put(authorStore.actions.loadProfile(authorId));
  }

  yield* call(() => track('Author', { id: authorId }));
}
