import {
  memo, useCallback, useEffect, useRef, useState,
} from 'react';
import { createUseStyles } from 'react-jss';
import cn from 'classnames';
import { Box, type Radius, useTheme } from '@mui/joy';
import { createPortal } from 'react-dom';

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';

import {
  $getSelection,
  $isRangeSelection,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  UNDO_COMMAND,
} from 'lexical';

import Icon from 'ui/Icon';

import useSelectionCoordinates from './hooks/useSelectionCoordinates';
import useContainerStyles from './hooks/useContainerStyles';

import BlockTypeSelector from './controls/BlockTypeSelector';
// import LinkButton from './controls/LinkButton';
import Divider from './ui/Divider';

const LowPriority = 1;

const ToolbarPlugin = () => {
  const theme = useTheme();
  const classes = useStyles({
    radius: theme.radius,
  });

  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef(null);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);

  const { coordinates: selectionCoordinates, isPrepared: isSelectionPrepared } = useSelectionCoordinates();
  const toolbarStyle = useContainerStyles(selectionCoordinates, isSelectionPrepared);

  const $updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));
      setIsStrikethrough(selection.hasFormat('strikethrough'));
    }
  }, []);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          $updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, _newEditor) => {
          $updateToolbar();
          return false;
        },
        LowPriority,
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        LowPriority,
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        LowPriority,
      ),
    );
  }, [editor, $updateToolbar]);

  return createPortal(
    <Box component="div" className={classes.toolbar} sx={toolbarStyle} data-control="toolbar" ref={toolbarRef}>
      <button
        type="button"
        disabled={!canUndo}
        onClick={() => {
          editor.dispatchCommand(UNDO_COMMAND, undefined);
        }}
        aria-label="Undo"
      >
        <Icon family="classic" name="rotate-left" color="inherit" size="lg" fw />
      </button>
      <button
        type="button"
        disabled={!canRedo}
        onClick={() => {
          editor.dispatchCommand(REDO_COMMAND, undefined);
        }}
        aria-label="Redo"
      >
        <Icon family="classic" name="rotate-right" color="inherit" size="lg" fw />
      </button>
      <Divider />
      <BlockTypeSelector />
      <Divider />
      <button
        type="button"
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
        }}
        className={cn(`${isBold ? 'active' : ''}`)}
        aria-label="Format Bold"
      >
        <Icon family="classic" name="bold" color="inherit" size="lg" fw />
      </button>
      <button
        type="button"
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
        }}
        className={cn(`${isItalic ? 'active' : ''}`)}
        aria-label="Format Italics"
      >
        <Icon family="classic" name="italic" color="inherit" size="lg" fw />
      </button>
      <button
        type="button"
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
        }}
        className={cn(`${isUnderline ? 'active' : ''}`)}
        aria-label="Format Underline"
      >
        <Icon family="classic" name="underline" color="inherit" size="lg" fw />
      </button>
      <button
        type="button"
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough');
        }}
        className={cn(`${isStrikethrough ? 'active' : ''}`)}
        aria-label="Format Strikethrough"
      >
        <Icon family="classic" name="strikethrough" color="inherit" size="lg" fw />
      </button>
      {/* <LinkButton /> */}
      <Divider />
      <button
        type="button"
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left');
        }}
        aria-label="Left Align"
      >
        <Icon family="classic" name="align-left" color="inherit" size="lg" fw />
      </button>
      <button
        type="button"
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center');
        }}
        aria-label="Center Align"
      >
        <Icon family="classic" name="align-center" color="inherit" size="lg" fw />
      </button>
      <button
        type="button"
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right');
        }}
        aria-label="Right Align"
      >
        <Icon family="classic" name="align-right" color="inherit" size="lg" fw />
      </button>
      <button
        type="button"
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify');
        }}
        aria-label="Justify Align"
      >
        <Icon family="classic" name="align-justify" color="inherit" size="lg" fw />
      </button>
      {' '}
    </Box>,
    document.body,
  );
};

const useStyles = createUseStyles<
  | 'toolbar',
  {
    radius?: Radius,
  }
>(() => ({
  toolbar: {
    '&': {
      position: 'fixed',
      display: 'flex',
      padding: 4,
      gap: 2,
      verticalAlign: 'middle',
      borderRadius: (props) => props.radius?.xl,
    },
    '& button': {
      border: 0,
      display: 'flex',
      alignItems: 'center',
      background: 'none',
      borderRadius: (props) => props.radius?.lg,
      padding: '8px',
      cursor: 'pointer',
      verticalAlign: 'middle',
      '&:disabled': {
        cursor: 'not-allowed',
      },
      '& i.format': {
        backgroundSize: 'contain',
        display: 'flex',
        height: '18px',
        width: '18px',
        marginTop: '2px',
        verticalAlign: '-0.25em',
        opacity: 0.6,
        '&:disabled': {
          opacity: 0.2,
        },
      },
    },
  },
}));

export default memo(ToolbarPlugin);
