import createCachedSelector from 're-reselect';
import { type DefaultRootState } from 'react-redux';

const selectorKey = (_state: DefaultRootState, method: 'signUp') => method;

const extraDataSelector = createCachedSelector(
  (state: DefaultRootState, method: 'signUp') => state.auth[method].extraData,
  (data) => data,
)(
  selectorKey,
);

export default (method: 'signUp') => (state: DefaultRootState) => extraDataSelector(state, method);
