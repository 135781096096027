import {
  cancel, takeEvery,
  type SagaReturnType, put,
} from 'redux-saga/effects';

import { getRoute } from 'navigation/methods';

import { call, select } from 'store/utils/saga/effects';
import * as navigationStore from 'store/nodes/navigation';

import { actions } from '../slice';

export const config = {
  action: navigationStore.actions.runPageHandler.type,
  method: takeEvery,
};

export function* func(
  action: SagaReturnType<typeof navigationStore.actions.runPageHandler>,
) {
  const route = yield* call(() => getRoute<'Note'>());
  if (!route.isScreen('Note')) {
    yield cancel(); return;
  }

  if (route.params.id === 'create') {
    yield put(actions.setSelectedCopilotContext({ value: 'library-fulltext' }));
    yield cancel(); return;
  }

  const id = Number(route.params.id);
  if (Number.isNaN(id)) {
    yield cancel(); return;
  }

  const [prevPathname] = yield* select(navigationStore.selectors.pathname);
  if (prevPathname !== '/note/create') {
    yield put(actions.setSelectedCopilotContext({ value: 'note' }));
    yield put(actions.loadById({ id }));
  }
}
