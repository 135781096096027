import { useMemo, useState } from 'react';

const useEditorState = (initialValue: string | null = null) => {
  const [value, setValue] = useState<string | null>(initialValue);

  return useMemo(() => {
    const text = (value || '').replace(/(<([^>]+)>)/gi, '');
    return ({
      onChange: setValue,
      html: value || '',
      text: text || '',
      hasContent: !!text,
    });
  }, [value]);
};

export default useEditorState;
