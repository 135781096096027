import {
  memo, useCallback, type MouseEvent,
} from 'react';
import { Box, Button } from '@mui/joy';

const arr = [
  {
    label: 'Extract key ideas',
    text: 'Extract key ideas from the text / the collection, outline them in a bulleted list. Do not invent things. Be concise.',
  },
  {
    label: 'Write a tweet',
    text: 'Write a short sharp twitter post based on the key ideas described in the list of texts. The output text should be a continuous narrative '
      + 'interlinking the ideas. Do not invent things. Important: be concise! Output length must not exceed 80 tokens or 5 sentences!',
  },
  {
    label: 'Brainstorm on key ideas',
    text: 'Brainstorm on the key ideas described in the list of texts / the text. The output text should be a list of ideas that emerge after'
      + ' interlinking the key ideas in texts / text. Do not invent things. You can add a couple of crazy creative ideas in the end without a number or bullet. Important:'
      + ' be concise! Output length must not exceed 150 tokens or 8 sentences!',
  },
];

type HotCommandsProps = {
  onSendCommand?: (label: string, text: string) => void
  disabled?: boolean,
  isAnswerTyping?: boolean,
}

const HotCommands = (props: HotCommandsProps) => {
  const {
    onSendCommand,
    disabled,
    isAnswerTyping,
  } = props;

  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    const id = event.currentTarget.getAttribute('data-id');
    if (!arr[Number(id)]) {
      return;
    }
    const { label, text } = arr[Number(id)];
    onSendCommand?.(label, text);
  }, [onSendCommand]);

  return (
    <Box
      display="flex"
      alignSelf="flex-end"
      justifyContent="flex-end"
      flexWrap="wrap"
      gap={1}
      width="50%"
    >
      {arr.map((item, key) => (
        <Button
          key={item.label}
          variant="outlined"
          color="neutral"
          data-id={key}
          onClick={handleClick}
          size="sm"
          disabled={disabled || isAnswerTyping}
          sx={{
            width: 'fit-content',
            alignSelf: 'flex-end',
            fontWeight: 400,
            fontSize: 'var(--joy-fontSize-xs)',
            '--Button-radius': '0.5em',
            '--Button-paddingBlock': '0.25em',
            '--Button-minHeight': '1em',
            borderColor: (theme) => theme.palette.background.level3,
          }}
        >
          {item.label}
        </Button>
      ))}
    </Box>
  );
};

export default memo(HotCommands);
