import React, {
  type ReactElement,
  memo, useCallback, useState,
} from 'react';
import { MacScrollbar } from 'mac-scrollbar';
import {
  Box, IconButton, Tooltip, Typography,
} from '@mui/joy';

import type {
  CollectionType,
} from 'app/entities';
import { useNavigate } from 'navigation/hooks';

import { useColorScheme } from 'hooks';

import { useDispatcher, useSelector } from 'store/utils/redux/hooks';
import * as userStore from 'store/nodes/user';

import { controller as modal } from 'components/Modal2';
import CardSmall from 'components/CardSmall';

import Icon from 'ui/Icon';
import EmptyPanel from 'screens/Profile/elements/EmptyPanel';
import CollectionsSwiper from 'screens/Profile/elements/Playlists/ui/CollectionsSwiper';
import Edit from './elements/Edit';
import Delete from './elements/Delete';

const defaultProps = {
  editable: false,
};

type PlaylistsProps = {
  userLogin?: string,
  bigLabel?: boolean,
  editable?: boolean,
} & typeof defaultProps;

const Playlists = (props: PlaylistsProps): ReactElement | null => {
  const {
    userLogin,
    bigLabel,
    editable,
  } = props;

  const navigate = useNavigate();
  const dispatcher = useDispatcher();
  const colorScheme = useColorScheme();

  const [isEditable, setEditable] = useState(false);

  const data = useSelector(userStore.selectors.getByLogin(userLogin));

  const playlistIds = data?.playlistIds || [];

  const handleOpen = useCallback((type: string, id: number) => {
    navigate('Playlist', { resourceId: id });
  }, []);

  const handleEditStart = useCallback(() => {
    setEditable(true);
  }, []);

  const handleEditCancel = useCallback(() => {
    setEditable(false);
  }, []);

  const handleAddCollectionPress = useCallback(() => {
    if (!data?.isMy) {
      return;
    }
    dispatcher.modal.open('AddorEditCollection', {
      create: {
        withItems: [{
          resourceType: 'material',
        }],
      },
    });
  }, [data?.isMy]);

  const handleEditPlaylist = useCallback((edit: CollectionType) => {
    dispatcher.modal.open('AddorEditCollection', { edit: { id: edit?.id } });
  }, []);

  const handleDeletePlaylist = useCallback((context: CollectionType) => {
    dispatcher.playlist.remove({ id: context.id });
  }, []);

  if (!userLogin || !data) {
    return null;
  }

  return (
    <Box>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Typography fontSize={bigLabel ? 22 : 20} fontWeight="bolder" flex={1}>Collections</Typography>
        {userLogin === 'my' && editable && (
          <Tooltip arrow variant="outlined" size="sm" title="Edit or delete collection">
            <IconButton onClick={!isEditable ? handleEditStart : handleEditCancel}>
              <Icon size="xl" name="gear" fw weight="light" color="primary" />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      {data.isMy && (!Array.isArray(playlistIds) || !playlistIds.length) && (
        <EmptyPanel mt={2} sx={{ cursor: 'pointer' }} onClick={handleAddCollectionPress} text="Your collections will be here" />
      )}
      {!data.isMy && (!Array.isArray(playlistIds) || !playlistIds.length) && (
        <EmptyPanel mt={2} text={`${data.name} has no collections`} />
      )}
      {Array.isArray(playlistIds) && playlistIds.length > 0 && (
        <CollectionsSwiper playlistIds={playlistIds} isEditable={isEditable} />
      )}
    </Box>
  );
};

Playlists.defaultProps = defaultProps;
export default memo(Playlists);
