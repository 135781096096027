import {
  takeLatest, put, cancel, delay,
} from 'redux-saga/effects';
import { track } from '@amplitude/analytics-react-native';

import { navigate } from 'navigation/methods';

import * as api from 'services/api';

import Alert from 'components/Alert';

import { call } from 'store/utils/saga/effects';
import * as userStore from 'store/nodes/user';
import * as contentStore from 'store/nodes/content';
import * as communityStore from 'store/nodes/community';
import * as libraryStore from 'store/nodes/library';

import * as actions from '../actions';

export const config = {
  action: actions.logOut.type,
  method: takeLatest,
};

export function* func() {
  const result = yield* call(() => api.resource.auth.logout());
  const [error] = !Array.isArray(result.error) ? [result.error] : result.error;
  if (error) {
    Alert.error(error?.message || 'Server error #6');
    yield put(actions.logOutDone(error));
    yield cancel(); return;
  }

  yield put(userStore.actions.logOut());
  yield put(contentStore.actions.logOut());
  yield put(communityStore.actions.logOut());
  yield put(libraryStore.actions.logOut());

  yield delay(50);
  yield put(actions.logOutDone());

  yield* call(() => track('Logout'));

  yield* call(() => navigate('Ask'));
}
