import {
  type FC, memo, type MouseEvent, useCallback,
} from 'react';
import { IconButton, Tooltip } from '@mui/joy';
import { type DefaultRootState, useStore } from 'react-redux';

import Copilot from 'widgets/Copilot';
import Icon from 'ui/Icon';

import { useSelector } from 'store/utils/redux/hooks';
import * as noteStore from 'store/nodes/note';

import * as copilotStore from 'widgets/Copilot/store';
import type { AnswerType } from 'widgets/Copilot/store/types';

import useIsMy from '../model/useIsMy';

type CopilotConnectorProps = {
  id: number | 'create',
  onCopyToText: (text: string) => void,
}

const CopilotConnector: FC<CopilotConnectorProps> = (props) => {
  const {
    id,
    onCopyToText,
  } = props;

  const store = useStore();
  const selectedContext = useSelector(noteStore.selectors.selectedContext);
  const isMy = useIsMy(id);

  const handleAddToText = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    const noteId = event.currentTarget?.getAttribute('data-id');
    if (!noteId) {
      return;
    }
    const data = copilotStore.selectors.dataById<AnswerType>(noteId)(store.getState() as DefaultRootState);
    if (!data?.payload?.text) {
      return;
    }
    onCopyToText?.(data.payload.text);
  }, [store]);

  return (
    <Copilot
      variant={selectedContext}
      resourceId={id === 'create' || selectedContext === 'library-fulltext' ? 0 : id}
      slotsProps={{
        answer: {
          actionsBar: [
            isMy && (
              <Tooltip arrow variant="outlined" size="sm" title="Move to text" disableInteractive>
                <IconButton onClick={handleAddToText}>
                  <Icon name="left" weight="regular" color="inherit" />
                </IconButton>
              </Tooltip>
            ),
            'Reload',
            'ToClipboard',
            !isMy && 'ToNote',
          ].filter(Boolean),
        },
      }}
    />
  );
};

export default memo(CopilotConnector);
