import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'store/utils/redux/hooks';
import * as noteStore from 'store/nodes/note';
import { marked } from 'marked';

const prepareHtml = (data: { text?: string | null } | null): null | string => {
  if (!data) {
    return null;
  }
  if (!data.text) {
    return '';
  }
  return marked(data.text, { async: false }) as string;
};

const useData = (id: number | 'create') => {
  const data = useSelector((state) => {
    if (id === 'create') {
      return null;
    }
    return noteStore.selectors.dataById(id)(state);
  });

  const isLoading = useSelector((state) => {
    if (id === 'create') {
      return true;
    }
    return noteStore.selectors.isLoading({ noteId: id })(state);
  });

  const [initHtml, setInitHtml] = useState<string | null>(prepareHtml(data));

  useEffect(() => {
    setInitHtml((prevState) => {
      if (prevState === null && typeof data?.text === 'string') {
        return prepareHtml(data);
      }
      return prevState;
    });
  }, [data]);

  return useMemo(() => ({
    data,
    initHtml,
    isLoading,
  }), [
    data,
    initHtml,
    isLoading,
  ]);
};

export default useData;
