import { createReducer } from '@reduxjs/toolkit';

import type { AppStore } from './types';
import * as actions from './actions';

const initialState: AppStore = {
  ready: false,
  prepared: false,
  showSplashScreen: true,
  dialogs: [],
};

export default createReducer(initialState, (builder) => builder
  .addCase(actions.ready, (state, action) => {
    state.ready = true;
  })
  .addCase(actions.prepared, (state, action) => {
    state.prepared = true;
  })
  .addCase(actions.hideSplashScreen, (state, action) => {
    state.showSplashScreen = false;
  })
  .addCase(actions.dialogShow, (state, action) => {
    const { id, body, buttons } = action.payload;
    const position = state.dialogs.findIndex((dialog) => dialog.id === id);
    if (position > -1) {
      return;
    }
    state.dialogs.push({
      id,
      isVisible: true,
      body,
      buttons,
    });
  })
  .addCase(actions.dialogHide, (state, action) => {
    const { id } = action.payload;
    const position = state.dialogs.findIndex((dialog) => dialog.id === id);
    if (position === -1) {
      return;
    }
    state.dialogs[position].isVisible = false;
  })
  .addCase(actions.dialogRemove, (state, action) => {
    const { id } = action.payload;
    const position = state.dialogs.findIndex((dialog) => dialog.id === id);
    if (position === -1) {
      return;
    }
    state.dialogs.splice(position, 0);
  }));
