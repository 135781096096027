import {
  useCallback, useRef, useState,
} from 'react';
import { md5 } from 'js-md5';

import { useDispatcher } from 'store/utils/redux/hooks';

const useSave = () => {
  const dispatcher = useDispatcher();
  const saveTimerRef = useRef<any>(null);

  const [_, setPrevState] = useState<string | null>(null);

  return useCallback((id: number, html: string, text: string) => {
    setPrevState((prevState) => {
      if (prevState === null) {
        return md5(html || '');
      }
      const newState = md5(html || '');
      if (prevState === newState) {
        return prevState;
      }
      if (saveTimerRef.current) {
        clearTimeout(saveTimerRef.current);
      }
      saveTimerRef.current = setTimeout(() => {
        dispatcher.note.update({ id, html });
      }, 1000);
      return newState;
    });
  }, []);
};

export default useSave;
