import { type DefaultRootState } from 'react-redux';
import { createCachedSelector } from 're-reselect';

const dataByIdSelector = createCachedSelector(
  (state: DefaultRootState, resourceId: number) => state.note.data[resourceId],
  (note) => {
    if (!note) {
      return null;
    }
    return note;
  },
)(
  (_state_, id) => id,
);
export default (id?: number) => (state: DefaultRootState) => {
  return dataByIdSelector(state, id || 0);
};
