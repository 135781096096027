import React, { memo, useCallback, useMemo } from 'react';

import { Box, IconButton, Tooltip } from '@mui/joy';

import Icon from 'ui/Icon';
import { useDispatcher } from 'store/utils/redux/hooks';
import { useSelector } from 'react-redux';
import * as copilotStore from 'widgets/Copilot/store';
import TutorialStep from 'components/TutorialStep';
import { useSearchMethod } from 'hooks';

const RightBar = () => {
  const dispatcher = useDispatcher();
  const { type: searchMethodType } = useSearchMethod();

  const rightBarWidth = useSelector(copilotStore.selectors.rightBarWidth);
  const isShow = useSelector(copilotStore.selectors.rightBarIsShow);
  const isUnavailable = searchMethodType === 'Title';

  const handleCopilot = useCallback(() => {
    if (isUnavailable) {
      return;
    }
    if (isShow) {
      dispatcher.copilot.close();
    } else {
      dispatcher.copilot.open();
    }
  }, [isShow, isUnavailable]);

  const handlePlusOpen = useCallback(() => {
    dispatcher.plus.open({ toCollection: { append: true } });
  }, []);

  const isShowPrepared = useMemo(() => {
    if (isUnavailable) {
      return false;
    }
    return isShow;
  }, [isUnavailable, isShow]);

  const copilotButtonTooltip = useMemo(() => {
    if (isUnavailable) {
      return 'Copilot is not available in search by title mode';
    }
    return isShowPrepared ? 'Close AI Copilot' : 'Open AI Copilot';
  }, [isUnavailable, isShowPrepared]);

  return (
    <Box display="flex" flex={1}>
      <Box alignSelf="flex-end" flex={1}>
        <Tooltip arrow variant="outlined" size="sm" title={copilotButtonTooltip} disableInteractive>
          <IconButton
            size="sm"
            color="neutral"
            variant="soft"
            onClick={handleCopilot}
            sx={{
              '&': {
                cursor: isUnavailable ? 'default' : undefined,
              },
              '&:active': {
                backgroundColor: (theme) => (isUnavailable ? theme.palette.background.level2 : undefined),
              },
            }}
          >
            <Icon name={isShowPrepared ? 'arrow-right-long-to-line' : 'arrow-left-long-to-line'} fw weight="light" color="primary" />
          </IconButton>
        </Tooltip>
      </Box>
      <Box width={isShowPrepared ? `${rightBarWidth - 38}px` : 8} />
      <Box>
        <TutorialStep stepIndex={1} title="Add to library" description="Save any urls, drop pdfs or enter notes here">
          <Tooltip arrow variant="outlined" size="sm" title="Add to library" disableInteractive>
            <IconButton
              size="sm"
              color="primary"
              variant="solid"
              onClick={handlePlusOpen}
            >
              <Icon name="plus" size="1x" fw weight="regular" color="#ffffff" />
            </IconButton>
          </Tooltip>
        </TutorialStep>
      </Box>
    </Box>
  );
};

export default memo(RightBar);
