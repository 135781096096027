import {
  memo, useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import {
  DialogTitle,
  Modal,
  ModalClose,
  ModalDialog,
  DialogContent,
  DialogActions,
  Box,
  Button,
  Typography,
} from '@mui/joy';
import appSumoMatchModal from 'data/appSumoMatchModal.json';

import { useDispatcher } from 'store/utils/redux/hooks';

import * as modalStore from 'widgets/modals/store';

const ProductVideo = () => {
  const dispatcher = useDispatcher();
  const { isOpen, params } = useSelector(modalStore.selectors.state('AppSumoMatch'));

  const handleModalClose = useCallback(async () => {
    dispatcher.modal.close('AppSumoMatch');
  }, []);

  const handleReplace = useCallback(async () => {
    dispatcher.modal.close('AppSumoMatch');
    dispatcher.subscription.replaceTo({ target: 'AppSumo', context: params?.context });
  }, [params?.context]);

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <ModalDialog
        variant="soft"
        size="lg"
        sx={{
          maxWidth: 480,
          '--ModalClose-radius': '1.125em',
        }}
      >
        <ModalClose onClick={handleModalClose} />
        {params?.status === 'error' && (
          <>
            <DialogTitle sx={{ mt: -1 }}>{appSumoMatchModal.error.title}</DialogTitle>
            <DialogContent
              sx={{
                '--Card-padding': '3rem',
              }}
            >
              <Typography>{appSumoMatchModal.error.text}</Typography>
            </DialogContent>
            <DialogActions sx={{ pt: 0 }}>
              <Box display="flex" justifyContent="center" flex={1} gap={1}>
                <Button color="neutral" onClick={handleModalClose}>{appSumoMatchModal.error.actions.close}</Button>
              </Box>
            </DialogActions>
          </>
        )}
        {params?.status === 'invalid_code' && (
          <>
            <DialogTitle sx={{ mt: -1 }}>{appSumoMatchModal.invalidCode.title}</DialogTitle>
            <DialogContent
              sx={{
                '--Card-padding': '3rem',
              }}
            >
              <Typography>{appSumoMatchModal.invalidCode.text}</Typography>
            </DialogContent>
            <DialogActions sx={{ pt: 0 }}>
              <Box display="flex" justifyContent="center" flex={1} gap={1}>
                <Button color="neutral" onClick={handleModalClose}>{appSumoMatchModal.invalidCode.actions.close}</Button>
              </Box>
            </DialogActions>
          </>
        )}
        {params?.status === 'exists_stripe' && (
          <>
            <DialogTitle sx={{ mt: -1 }}>{appSumoMatchModal.existsStripe.title}</DialogTitle>
            <DialogContent
              sx={{
                '--Card-padding': '3rem',
              }}
            >
              <Typography>{appSumoMatchModal.existsStripe.text}</Typography>
            </DialogContent>
            <DialogActions sx={{ pt: 0 }}>
              <Box display="flex" justifyContent="center" flex={1} gap={1}>
                <Button color="neutral" onClick={handleModalClose}>{appSumoMatchModal.existsStripe.actions.close}</Button>
                {appSumoMatchModal.existsStripe.actions.replace.isActive && (
                  <Button color="danger" onClick={handleReplace}>{appSumoMatchModal.existsStripe.actions.replace.label}</Button>
                )}
              </Box>
            </DialogActions>
          </>
        )}
        {params?.status === 'exists_appsumo' && (
          <>
            <DialogTitle sx={{ mt: -1 }}>{appSumoMatchModal.existsAppsumo.title}</DialogTitle>
            <DialogContent
              sx={{
                '--Card-padding': '3rem',
              }}
            >
              <Typography>{appSumoMatchModal.existsAppsumo.text}</Typography>
            </DialogContent>
            <DialogActions sx={{ pt: 0 }}>
              <Box display="flex" justifyContent="center" flex={1} gap={1}>
                <Button color="neutral" onClick={handleModalClose}>{appSumoMatchModal.existsAppsumo.actions.close}</Button>
              </Box>
            </DialogActions>
          </>
        )}
        {params?.status === 'already_matched' && (
          <>
            <DialogTitle sx={{ mt: -1 }}>{appSumoMatchModal.alreadyMatched.title}</DialogTitle>
            <DialogContent
              sx={{
                '--Card-padding': '3rem',
              }}
            >
              <Typography>{appSumoMatchModal.alreadyMatched.text}</Typography>
            </DialogContent>
            <DialogActions sx={{ pt: 0 }}>
              <Box display="flex" justifyContent="center" flex={1} gap={1}>
                <Button color="neutral" onClick={handleModalClose}>{appSumoMatchModal.alreadyMatched.actions.close}</Button>
              </Box>
            </DialogActions>
          </>
        )}
      </ModalDialog>
    </Modal>
  );
};

export default memo(ProductVideo);
