import {
  useCallback, useEffect,
} from 'react';
import { useTheme } from '@mui/joy';
import { Platform } from 'react-native';
import { StatusBar } from 'expo-status-bar';

import Navigation from 'navigation';

import { useDispatcher, useSelector } from 'store/utils/redux/hooks';

import { useColorScheme, useThemeColor } from 'hooks';

import Splash from 'components/Splash';
import Preparing from 'components/Preparing';
import Disclaimer from 'components/Disclaimer';

type MainProps = {
  isAppReady: boolean,
  onNavigationReady?: () => void,
}

const Main = (props: MainProps) => {
  const {
    isAppReady = false,
    onNavigationReady,
  } = props;

  const dispatcher = useDispatcher();
  const theme = useTheme();

  const colorScheme = useColorScheme();
  const background = useThemeColor({ dark: '#000000', light: '#ffffff' });

  const isAppPrepared = useSelector((state) => state.app.prepared);
  const isShowSplashScreen = useSelector((state) => state.app.showSplashScreen);

  const showApp = isAppReady && isAppPrepared;

  useEffect(() => {
    if (Platform.OS !== 'web') {
      return;
    }
    document.body.style.background = theme.palette.background.body; // background || 'none';
  }, [background]);

  const handleLocationChange = useCallback(() => {
    dispatcher.navigation.locationChange();
  }, []);

  return (
    <>
      {showApp && <StatusBar />}
      {showApp && <Disclaimer />}
      <Navigation
        colorScheme={colorScheme}
        onReady={onNavigationReady}
        onLocationChange={handleLocationChange}
      />
      {isShowSplashScreen && <Preparing />}
    </>
  );
};

export default Main;
