import { type FC, memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import type { BoxProps } from '@mui/joy';

import { useDispatcher } from 'store/utils/redux/hooks';
import * as copilotStore from 'widgets/Copilot/store';

import DropdownMenu from 'ui/DropdownMenu';

export type ModelSelectProps = {} & Pick<BoxProps, 'm' | 'mx' | 'my' | 'mt' | 'mr' | 'mb' | 'ml'>

const ModelSelect: FC<ModelSelectProps> = (props) => {
  const dispatcher = useDispatcher();

  const selectedModel = useSelector(copilotStore.selectors.selectedModel);
  const availableModels = useSelector(copilotStore.selectors.availableModels);
  const isShow = useSelector(copilotStore.selectors.rightBarIsShow);
  const isAnswerTyping = useSelector(copilotStore.selectors.isAnswerTyping('*'));

  const handleChange = useCallback((id: number) => {
    dispatcher.copilot.setSelectedModel({ id: Number(id) });
  }, []);

  return (
    <>
      {isShow && selectedModel && (
        <DropdownMenu
          disabled={isAnswerTyping || availableModels.length === 1}
          options={availableModels}
          value={selectedModel.id}
          optionValueName="id"
          optionLabelName="model"
          onChange={handleChange}
          {...props}
        />
      )}
    </>
  );
};

export default memo(ModelSelect, (prevProps, nextProps) => isEqual(prevProps, nextProps));
