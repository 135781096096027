import {
  type LexicalNode,
  $getNodeByKey,
  $getRoot,
  $getSelection,
  $isParagraphNode,
  $isRangeSelection, $isRootNode,
  $isTextNode,
} from 'lexical';

const insertNode = (strategy: 'start' | 'current' | 'end', nodes: LexicalNode[]) => {
  const root = $getRoot();

  if (strategy === 'start') {
    [...nodes].forEach((node) => root.getFirstChild()?.insertBefore(node, true));
    return true;
  }

  if (strategy === 'end') {
    [...nodes].reverse().forEach((node) => root.getFirstChild()?.insertAfter(node, true));
    return true;
  }

  const selection = $getSelection();
  if (!selection || !$isRangeSelection(selection) || !selection.anchor) {
    return false;
  }

  const targetNode = $getNodeByKey(selection.anchor.key);
  if (!targetNode) {
    return false;
  }

  if ($isParagraphNode(targetNode) && targetNode.getChildrenSize() === 0) {
    [...nodes].reverse().forEach((node) => targetNode.insertAfter(node, true));
    targetNode.remove();
    return true;
  }

  if ($isTextNode(targetNode)) {
    const parent = targetNode.getParent();
    if (!parent) {
      return false;
    }
    if (parent.getTextContent().trim().length === 0) {
      [...nodes].reverse().forEach((node) => parent.insertAfter(node, true));
      parent.remove();
      return true;
    }
    [...nodes].reverse().forEach((node) => parent.insertAfter(node, true));
    return true;
  }

  if ($isRootNode(targetNode)) {
    targetNode.append(...nodes);
    return true;
  }

  return false;
};

export default insertNode;
