import { type CSSProperties, useMemo } from 'react';
import { useTheme, useColorScheme, type BoxProps } from '@mui/joy';

const useContainerStyles = (
  selectionCoordinates: { top: number, left: number, right: number, bottom: number } | null,
  isSelectionPrepared: boolean,
) => {
  const theme = useTheme();
  const { colorScheme } = useColorScheme();

  return useMemo((): BoxProps['sx'] => {
    if (!selectionCoordinates) {
      return {
        opacity: 0,
        pointerEvents: 'none',
      };
    }
    return {
      opacity: isSelectionPrepared ? 1 : 0,
      pointerEvents: isSelectionPrepared ? 'all' : 'none',
      top: 0,
      left: 0,
      transform: `
        translate3D(${selectionCoordinates.left}px, ${selectionCoordinates.top}px, 0)
        translate3d(-50%, -100%, 0)
        translateY(-28px)
        translateX(${(selectionCoordinates.right - selectionCoordinates.left) / 2}px)
      `,
      transition: `${isSelectionPrepared ? 'transform 50ms ease, ' : ''}opacity 50ms ease 100ms`,
      boxShadow: `${theme.shadow.xl}, ${theme.shadow.xl}, ${theme.shadow.sm}`,
      backgroundColor: `var(${colorScheme === 'light' ? '--joy-palette-common-white' : '--joy-palette-neutral-800'})`,
      border: `1px solid var(${colorScheme === 'light' ? '--joy-palette-neutral-50' : '--joy-palette-neutral-900'})`,
      '& button': {
        color: 'var(--joy-palette-text-primary)',
      },
      '& button[disabled]': {
        opacity: 0.25,
      },
      '& button:hover:not([disabled])': {
        backgroundColor: `var(${colorScheme === 'light' ? '--joy-palette-neutral-100' : '--joy-palette-neutral-700'})`,
      },
      '& button:active:not([disabled])': {
        backgroundColor: `var(${colorScheme === 'light' ? '--joy-palette-neutral-200' : '--joy-palette-neutral-600'})`,
      },
      '& button.active, & button:hover.active': {
        backgroundColor: `var(${colorScheme === 'light' ? '--joy-palette-neutral-200' : '--joy-palette-neutral-600'})`,
      },
    };
  }, [selectionCoordinates, isSelectionPrepared, theme, colorScheme]);
};

export default useContainerStyles;
