import PlusBottomSheet from './components/PlusBottomSheet';
import EditMaterial from './components/EditMaterial';
import AddToCollection from './components/AddToCollection';
import AddorEditCollection from './components/AddorEditCollection';
import SearchUser from './components/SearchUser';
import ProductVideo from './components/ProductVideo';
import AppSumoMatch from './components/AppSumoMatch';

const Modals = () => (
  <>
    <PlusBottomSheet />
    <EditMaterial />
    <AddorEditCollection />
    <AddToCollection />
    <SearchUser />
    <ProductVideo />
    <AppSumoMatch />
  </>
);

export default Modals;
