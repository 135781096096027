import { useSelector } from 'store/utils/redux/hooks';
import * as noteStore from 'store/nodes/note';

const useIsMy = (id: number | 'create') => {
  return useSelector((state) => (
    id !== 'create' ? noteStore.selectors.isMy({ noteId: id })(state) : true
  ));
};

export default useIsMy;
