import { useMemo } from 'react';
import { stripTags, truncateText } from 'utils';

const useDocumentDescription = (text?: string) => useMemo(() => {
  if (!text) {
    return {
      processed: false,
    };
  }
  const match = text.match(/^.{0,600}?(<h[1-6]+.*?>.*?<\/h[1-6]+>)/i);
  const [, found] = match || [];
  let title: string | null = null;
  if (found) {
    title = stripTags(found);
  }

  const paragraph = truncateText(stripTags((found ? text?.replace(found.trim(), '') : text)?.replaceAll('</p>', '</p> ')), 1500);

  return {
    processed: true,
    title,
    paragraph,
    hasContent: !!title?.trim() && !!paragraph.trim(),
  };
}, [text]);

export default useDocumentDescription;
