import {
  useCallback, useRef,
} from 'react';

import { useDispatcher } from 'store/utils/redux/hooks';

const useCreate = () => {
  const dispatcher = useDispatcher();
  const creatingRef = useRef(false);

  return useCallback((html: string, text: string) => {
    if (creatingRef.current) {
      return;
    }
    creatingRef.current = true;
    dispatcher.note.add({ html });
  }, []);
};

export default useCreate;
