import {
  type PropsWithChildren, type ReactNode,
  memo, useCallback, useMemo,
} from 'react';
import {
  type StyleProp, type ViewStyle,
  Platform, ScrollView, StyleSheet,
} from 'react-native';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import { useFocusEffect, useRoute } from 'navigation/hooks';
import { unit } from 'utils';

import { useResponsive, useValueByScreenWidth } from 'hooks';

import * as menuStore from 'store/nodes/menu';
import { Page, View } from 'components/Themed';
import { useSetDesktopBarConfig } from 'components/UIBar';
import { useScrollMethods } from 'components/Scroller';

const defaultProps = {
  bodyMaxWidth: unit(1280),
};

type AdaptivePageProps = {
  desktopHeaderLeftPanel?: ReactNode | 'search' | 'ask',
  desktopHeaderMiddlePanel?: ReactNode,
  desktopHeaderRightPanel?: ReactNode,
  bodyMaxWidth?: number,
  paddingHorizontalScheme?: Record<number, number>,
  scrollToStart?: boolean,
} & typeof defaultProps;

const AdaptivePage = (props: PropsWithChildren<AdaptivePageProps>) => {
  const {
    children,
    desktopHeaderLeftPanel,
    desktopHeaderMiddlePanel,
    desktopHeaderRightPanel,
    bodyMaxWidth,
    paddingHorizontalScheme,
    scrollToStart,
  } = props;

  const route = useRoute();
  const responsive = useResponsive();
  const setDesktopBarConfig = useSetDesktopBarConfig();
  const scrollMethods = useScrollMethods();
  const paddingHorizontal = useValueByScreenWidth(paddingHorizontalScheme);

  const leftBarWidth = useSelector(menuStore.selectors.leftBarWidth);

  useFocusEffect(useCallback(() => {
    setDesktopBarConfig({
      headerLeftPanel: desktopHeaderLeftPanel,
      headerMiddlePanel: desktopHeaderMiddlePanel,
      headerRightPanel: desktopHeaderRightPanel,
    });
  }, [desktopHeaderLeftPanel, desktopHeaderMiddlePanel, desktopHeaderRightPanel]));

  useFocusEffect(useCallback(() => {
    if (scrollToStart) {
      scrollMethods.scrollToStart();
    }
  }, [scrollToStart, scrollMethods, route.name, JSON.stringify(route.params)]));

  const pageStyles = useMemo((): StyleProp<ViewStyle> => ({
    paddingTop: responsive.isLessThen.tablet ? unit(64) : unit(80),
    paddingLeft: responsive.isLessThen.tablet ? 0 : unit(leftBarWidth === 64 ? 0 : leftBarWidth),
  }), [responsive.isLessThen.tablet, leftBarWidth]);

  const bodyStyles = useMemo((): StyleProp<ViewStyle> => [
    styles.body,
    {
      paddingHorizontal: unit(paddingHorizontal),
      maxWidth: bodyMaxWidth,
    },
  ], [bodyMaxWidth, paddingHorizontal]);

  if (Platform.OS === 'web') {
    return (
      <Page
        componentsProps={{}}
        fullScreen
        style={pageStyles}
      >
        <View style={bodyStyles}>{children}</View>
      </Page>
    );
  }

  console.log('AdaptivePage ПОПРАВИТЬ ОТОБРАЖЕНИЕ ПРОКРУТКИ');
  return (
    <ScrollView style={styles.scroll}>
      {children}
    </ScrollView>
  );
};

AdaptivePage.defaultProps = defaultProps;

const styles = StyleSheet.create({
  scroll: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  body: {
    width: '100%',
    alignSelf: 'center',
    flex: 1,
  },
});

export default memo(AdaptivePage, (prevProps, nextProps) => isEqual(prevProps, nextProps));
