import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';

import type { SourceType, StateType, PlanType } from '../types';

export type SubscriptionStore = {
  source: SourceType;
  state: StateType;
  plans: Record<string, PlanType>;
  isPricingShow: boolean;
  isLoading: boolean;
  isProcessing: boolean;
  isOpeningPortal: boolean;
  isTrialProcessing: boolean;
}

const initialState: SubscriptionStore = {
  source: null,
  state: {
    status: null,
    plan: '-1',
    startDate: null,
    expirationDate: null,
    hasPaymentMethod: null,
    canceledAt: null,
    cancelAt: null,
  },
  plans: {},
  isPricingShow: false,
  isLoading: false,
  isProcessing: false,
  isOpeningPortal: false,
  isTrialProcessing: false,
};

const noteSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    reset: (state) => {
      const resetState = cloneDeep(initialState);
      state.state = resetState.state;
      state.plans = resetState.plans;
      state.isPricingShow = resetState.isPricingShow;
      state.isLoading = resetState.isLoading;
      state.isProcessing = resetState.isProcessing;
      state.isOpeningPortal = resetState.isOpeningPortal;
    },
    showPricing: (state) => {
      state.isPricingShow = true;
    },
    hidePricing: (state) => {
      state.isPricingShow = false;
    },
    loadState: (state) => {
      state.isLoading = true;
    },
    loadStateDone: (state, action: PayloadAction<{ source: SourceType, state: StateType | 'reset' }>) => {
      state.isLoading = false;
      state.source = action.payload.source;
      if (!action.payload.state) {
        return;
      }
      if (action.payload.state === 'reset') {
        state.state = cloneDeep(initialState.state);
        return;
      }
      state.state = action.payload.state;
    },
    setState: (state, action: PayloadAction<{ state: StateType }>) => {
      state.state = action.payload.state;
    },
    loadPlans: () => undefined,
    loadPlansDone: (state, action: PayloadAction<{ plans: PlanType[] }>) => {
      action.payload.plans.forEach((item) => {
        state.plans[item.planId] = item;
      });
    },
    subscribe: (state, action: PayloadAction<{ planId: string, hasTrial?: boolean }>) => {
      state.isProcessing = true;
    },
    subscribeDone: (state) => {
      state.isProcessing = false;
    },
    cancel: (state, action: PayloadAction) => {
      state.isProcessing = true;
    },
    cancelDone: (state) => {
      state.isProcessing = false;
    },
    openBillingPortal: (state, action: PayloadAction<{ hasTrial?: boolean, openMode?: 'blank' | 'popup' }>) => {
      state.isOpeningPortal = true;
    },
    openBillingPortalDone: (state) => {
      state.isOpeningPortal = false;
    },
    closeBillingPortal: () => undefined,
    replaceTo: (_, action: PayloadAction<{ target: 'AppSumo', context?: Record<string, any> }>) => undefined,
    tryFree: (state) => {
      state.isTrialProcessing = true;
    },
    tryFreeDone: (state) => {
      state.isTrialProcessing = false;
    },
  },
});

export const {
  reducer,
  actions,
} = noteSlice;
