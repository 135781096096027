import {
  forwardRef, memo,
} from 'react';
import {
  Box,
  ButtonGroup,
} from '@mui/joy';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

import RichTextPanel from 'ui/EditorInput/ui/RichTextPanel';
import ListPanel from 'ui/EditorInput/ui/ListPanel';
import HeadingPanel from 'ui/EditorInput/ui/HeadingPanel';
import LinkPanel from 'ui/EditorInput/ui/LinkPanel';
import LinkEditorPanel from 'ui/EditorInput/ui/LinkEditorPanel';

export type DefaultToolbarCoords = { x: number; y: number } | undefined;

const defaultProps = {
  hasRichPanel: true as boolean | undefined,
  hasListPanel: true as boolean | undefined,
  hasHeadingPanel: true as boolean | undefined,
};

type DefaultToolbarProps = {
  editor: ReturnType<typeof useLexicalComposerContext>[0];
  coords: DefaultToolbarCoords;
  setIsLinkEditMode: (value: boolean) => void;
  isLink: boolean;
  isLinkEditMode: boolean;
  hasRichPanel?: boolean;
  hasListPanel?: boolean;
  hasHeadingPanel?: boolean;
} & typeof defaultProps;

const DefaultToolbar = forwardRef<HTMLDivElement, DefaultToolbarProps>((props, forwardedRef) => {
  const {
    editor,
    coords,
    setIsLinkEditMode,
    isLink,
    isLinkEditMode,
    hasRichPanel,
    hasListPanel,
    hasHeadingPanel,
  } = props;

  const shouldShow = coords !== undefined;

  return (
    <Box
      ref={forwardedRef}
      aria-hidden={!shouldShow}
      sx={{
        position: 'absolute',
        top: coords?.y,
        left: coords?.x,
        visibility: shouldShow ? 'visible' : 'hidden',
        opacity: shouldShow ? 1 : 0,
        backgroundColor: (theme) => theme.palette.background.surface,
        boxShadow: (theme) => theme.shadow.sm,
        borderRadius: '0.5rem',
      }}
    >
      <ButtonGroup
        sx={{
          '& button:first-child': {
            borderBottomLeftRadius: '0.5rem!important',
            borderTopLeftRadius: '0.5rem!important',
          },
          '& button:last-child': {
            borderBottomRightRadius: '0.5rem!important',
            borderTopRightRadius: '0.5rem!important',
          },
          '& button:hover': {
            backgroundColor: (theme) => theme.palette.background.level1,
          },
          '& button:active': {
            backgroundColor: (theme) => theme.palette.background.level2,
          },
        }}
      >
        {hasHeadingPanel && (
          <HeadingPanel editor={editor} />
        )}
        {hasRichPanel && (
          <RichTextPanel editor={editor} />
        )}
        {hasListPanel && (
          <ListPanel editor={editor} />
        )}
        {/* <FontSizePanel editor={editor} /> */}
        {/* <FontColorPanel editor={editor} /> */}
        {/* <LinkPanel setIsLinkEditMode={setIsLinkEditMode} isLink={isLink} editor={editor} /> */}
        {/* <LinkEditorPanel */}
        {/*   editor={editor} */}
        {/*   isLinkEditMode={isLinkEditMode} */}
        {/*   setIsLinkEditMode={setIsLinkEditMode} */}
        {/* /> */}
      </ButtonGroup>
    </Box>
  );
});

DefaultToolbar.defaultProps = defaultProps;

export default memo(DefaultToolbar);
