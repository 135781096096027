import {
  memo, useCallback, useEffect, useState,
} from 'react';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  FormLabel,
  Textarea,
  Modal,
  ModalClose,
  ModalDialog,
  Stack,
  Button,
  Box,
  Switch,
} from '@mui/joy';
import { useDispatcher, useSelector } from 'store/utils/redux/hooks';
import * as modalStore from 'widgets/modals/store';
import * as playlistStore from 'store/nodes/playlist';
import * as myContentStore from 'store/nodes/myContent';
import * as userStore from 'store/nodes/user';

import { type CollectionType, type ImageType, type MaterialMetadataItemType } from 'app/entities';
import Preview from 'components/Preview';
import ImagePicker from 'components/ImagePicker';
import Alert from 'components/Alert';

const AddorEditCollection = () => {
  const dispatcher = useDispatcher();
  const { isOpen, params } = useSelector(modalStore.selectors.state('AddorEditCollection'));

  const originalData = useSelector(playlistStore.selectors.dataById(Number(params?.edit?.id)));

  const isPlaylistCreating = useSelector((state) => state.myContent.meta.isPlaylistCreating);
  const materialLastCreatedId = useSelector(myContentStore.selectors.materialLastCreatedId);
  const userProfile = useSelector(userStore.selectors.getMy);
  const isLegalUser = userProfile?.type === 'legal';

  const [formData, setFormData] = useState<CollectionType | null>(originalData);
  const [cover, setCover] = useState<ImageType | null>(null);

  useEffect(() => {
    if (originalData) {
      setFormData({
        ...originalData,
      });
    }
    if (originalData?.cover) {
      setCover(originalData.cover);
    }
  }, [originalData]);

  useEffect(() => {
    if (params?.create) {
      setFormData({
        title: '',
      } as CollectionType);
    }
  }, [params?.create]);

  useEffect(() => {
    if (!materialLastCreatedId) {
      return;
    }
    dispatcher.myContent.createPlaylistAddSelectedId(materialLastCreatedId);
    dispatcher.content.loadById([materialLastCreatedId]);
    dispatcher.myContent.addMaterialSetLastCreatedId(null);
  }, [materialLastCreatedId]);

  const handleChange = useCallback((event: { target: { name: string; value: string | boolean; }; }) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      cover,
      ...prevFormData as CollectionType,
      [name]: value,
    }));
  }, [cover]);

  const handleModalClose = useCallback(async () => {
    dispatcher.modal.close('AddorEditCollection');
    setFormData(null);
    setCover(null);
  }, []);
  const handleSubmit = useCallback(() => {
    if (!formData) {
      return;
    }
    if (/^\d+$/.test(formData.title)) {
      Alert.error('Please make are more meaningful title for you collection, otherwise it we will not be able to find it');
      return;
    }
    if (params?.create) {
      const materialsMetadata: MaterialMetadataItemType[] = [];
      if (Array.isArray(params?.create.withItems) && params.create.withItems.find((value) => !isNaN(value.resourceId))) {
        params?.create.withItems.forEach((value) => {
          materialsMetadata.push({
            type: value.resourceType,
            id: value.resourceId,
            position: 0,
            relatedToContentId: null,
            isSmartListRecommendation: false,
          });
        });
      }
      dispatcher.myContent.addPlaylist({
        title: formData.title,
        description: formData?.description,
        cover,
        isPrivate: isLegalUser ? true : formData?.isPrivate,
        materialsMetadata,
      });
    }
    if (params?.edit) {
      dispatcher.playlist.updateInBackground({
        id: formData.id,
        data: {
          title: formData.title,
          description: formData?.description,
          cover,
          isPrivate: isLegalUser ? true : formData?.isPrivate,
        },
      });
      handleModalClose();
    }
  }, [handleModalClose, formData, params?.edit, params?.create, cover, isLegalUser]);

  const handleCoverChange = useCallback((event: { type: 'select' | 'delete', value: ImageType | null }) => {
    if (event.type === 'select') {
      setCover(event.value);
    }
    if (event.type === 'delete') {
      setCover(null);
    }
  }, []);

  const previewRender = useCallback((value?: ImageType | null) => (
    <Preview uri={value?.url} />
  ), []);

  if (!formData) {
    return null;
  }

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <ModalDialog
        variant="soft"
        size="lg"
        sx={{
          width: 500,
          '--ModalClose-radius': '1.125em',
        }}
      >
        <ModalClose onClick={handleModalClose} />
        <DialogTitle sx={{ mt: -1 }}>{!params?.edit ? 'New Collection' : 'Edit Collection'}</DialogTitle>
        <DialogContent
          sx={{
            mx: 'calc(-1 * var(--Card-padding))',
            px: 'var(--Card-padding)',
          }}
        >
          <Box alignSelf="center">
            <ImagePicker
              render={previewRender}
              onChange={handleCoverChange}
              value={cover}
            />
          </Box>
          <Stack spacing={2}>
            <FormControl>
              <FormLabel>Title</FormLabel>
              <Textarea variant="plain" disabled={isPlaylistCreating} maxRows={2} value={formData.title || ''} name="title" onChange={handleChange} />
            </FormControl>
            <FormControl>
              <FormLabel>Description</FormLabel>
              <Textarea variant="plain" disabled={isPlaylistCreating} maxRows={5} value={formData.description || ''} name="description" onChange={handleChange} />
            </FormControl>
            {!isLegalUser && (
            <FormControl orientation="horizontal">
              <FormLabel>{formData.isPrivate ? 'Private collection' : 'Not private collection' }</FormLabel>
              <Switch
                size="lg"
                checked={formData.isPrivate}
                onChange={(e) => {
                  const event = {
                    target: {
                      name: 'isPrivate',
                      value: e.target.checked,
                    },
                  };
                  handleChange(event);
                }}
              />
            </FormControl>
            )}
          </Stack>
        </DialogContent>
        <DialogActions sx={{ pt: 0 }}>
          <Box display="flex" justifyContent="center" flex={1}>
            <Button
              loading={isPlaylistCreating}
              disabled={!formData?.title}
              onClick={handleSubmit}
            >
              {params?.create ? 'Create' : 'Save'}
            </Button>
          </Box>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default memo(AddorEditCollection);
