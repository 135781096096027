import { useMemo } from 'react';
import { type DefaultRootState, useDispatch, useSelector as useSelectorOrigin } from 'react-redux';

import { store } from 'store';

import * as modal from 'widgets/modals/store/actions';
import * as subscription from 'widgets/Subscription/store';
import * as copilot from 'widgets/Copilot/store';

import * as value from 'store/nodes/value/actions';
import * as app from 'store/nodes/app/actions';
import * as menu from 'store/nodes/menu/actions';
import * as message from 'store/nodes/message';
import * as navigation from 'store/nodes/navigation';
import * as auth from 'store/nodes/auth/actions';
import * as activity from 'store/nodes/activity/actions';
import * as comment from 'store/nodes/comment/actions';
import * as myContent from 'store/nodes/myContent/actions';
import * as common from 'store/nodes/common/actions';
import * as content from 'store/nodes/content/actions';
import * as playlist from 'store/nodes/playlist';
import * as profile from 'store/nodes/profile/actions';
import * as user from 'store/nodes/user/actions';
import * as author from 'store/nodes/author/actions';
import * as interaction from 'store/nodes/interaction/actions';
import * as search from 'store/nodes/search/actions';
import * as searchModal from 'store/nodes/searchModal/actions';
import * as searchUser from 'store/nodes/searchUser/actions';
import * as community from 'store/nodes/community/actions';
import * as library from 'store/nodes/library/actions';
import * as news from 'store/nodes/news/actions';
import * as smartNote from 'store/nodes/smartNote/actions';
import * as relation from 'store/nodes/relation/actions';
import * as plus from 'store/nodes/plus/actions';
import * as note from 'store/nodes/note';
import * as ask from 'store/nodes/ask';

export const useSelector = <Selected>(
  selector: (state: DefaultRootState) => Selected,
  equalityFn?: (left: Selected, right: Selected) => boolean,
) => useSelectorOrigin<DefaultRootState, Selected>(selector, equalityFn);

const handler = {
  get(target: any, propertyName: PropertyKey): any {
    return (...args: any[]) => {
      const action = target[propertyName].apply(null, args);
      store.dispatch(action);
    };
  },
};

const withHandler = <U extends {[key: string]: Record<string, any>}>(actionsMap: U): U => {
  const result = {} as {[key: string]: Record<string, any>};
  Object.keys(actionsMap).forEach((name) => {
    result[name] = new Proxy(actionsMap[name], handler);
  });
  return result as typeof actionsMap;
};

export const dispatcher = withHandler({
  value,
  app,
  menu,
  navigation: navigation.actions,
  auth,
  activity,
  comment,
  common,
  content,
  playlist: playlist.actions,
  myContent,
  profile,
  user,
  author,
  interaction,
  search,
  searchModal,
  searchUser,
  community,
  library,
  message: message.actions,
  news,
  smartNote,
  relation,
  // screenCollection,
  plus,
  modal,
  note: note.actions,
  copilot: copilot.actions,
  ask: ask.actions,
  subscription: subscription.actions,
});

export const useDispatcher = () => useMemo(() => dispatcher, []);

export {
  useDispatch,
};
