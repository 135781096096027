import {
  memo, useCallback,
} from 'react';
import {
  type StyleProp, type ViewStyle,
  StyleSheet,
} from 'react-native';
import { useSelector } from 'react-redux';
import { Box, IconButton, Tooltip } from '@mui/joy';
import { useNavigate, useRoute } from 'navigation/hooks';
import { saveLastRoute, unit } from 'utils';

import * as userStore from 'store/nodes/user';

import { View, Text } from 'components/Themed';
import Button from 'components/Button';
import PlusButton from 'components/PlusButton';
import AddNoteButton from 'components/AddNoteButton';

import { controller as modal } from 'components/Modal2';

import Icon from 'ui/Icon';
import TutorialStep from 'components/TutorialStep';

type UserMenuProps = {
  style?: StyleProp<ViewStyle>,
}

const UserMenu = (props: UserMenuProps) => {
  const {
    style,
  } = props;

  const navigate = useNavigate();
  const route = useRoute<'Profile'>();
  const userProfile = useSelector(userStore.selectors.getMy);

  const handleProfileSettings = useCallback(() => {
    modal.popup.profileSettings.open();
  }, []);

  const handleLogIn = useCallback(() => {
    saveLastRoute();
    navigate('Auth/Start');
  }, []);

  if (!userProfile) {
    return (
      <View style={[style, styles.UserMenu]}>
        <Button
          type="button"
          variant="outlined"
          height={30}
          onPress={handleLogIn}
          lightColor="#929292"
          darkColor="#77767E"
          radius="full"
          style={{ opacity: 0.8 }}
        >
          <Text size={15} lightColor="#000000" darkColor="#ffffff">Sign-In</Text>
        </Button>
      </View>
    );
  }

  return (
    <View style={[style, styles.UserMenu]}>
      {route.params?.login === 'my' && userProfile.type === 'default' && (
        <Tooltip arrow variant="outlined" size="sm" title="Edit profile" disableInteractive>
          <IconButton
            size="md"
            color="neutral"
            variant="soft"
            onClick={handleProfileSettings}
          >
            <Icon name="edit" fw weight="light" />
          </IconButton>
        </Tooltip>
      )}
      <AddNoteButton />
      <TutorialStep stepIndex={1} title="Add to library" description="Save any urls, drop pdfs or enter notes here">
        <PlusButton />
      </TutorialStep>
    </View>
  );
};

const styles = StyleSheet.create({
  UserMenu: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: unit(8),
  },
  button: {
    marginRight: unit(16),
  },
  buttonLast: {
    marginRight: 0,
  },
});

export default memo(UserMenu);
