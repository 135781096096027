import {
  memo, useCallback, useMemo,
} from 'react';
import { Box, CardContent, Typography } from '@mui/joy';
import { isEqual } from 'lodash';

import { type AuthorType, type NoteType, type UserType } from 'app/entities';

import Icon from 'ui/Icon';

import useAuthor from '../model/useAuthor';
import useDocumentDescription from '../model/useDocumentDescription';

import AuthorBlock from '../ui/AuthorBlock';

type NoteProps ={
  data?: NoteType,
  hasAuthor?: boolean,
  handleAuthorPress?: (value: UserType | AuthorType) => void
}

const Note = (props: NoteProps) => {
  const {
    data,
    handleAuthorPress,
    hasAuthor,
  } = props;

  const author = useAuthor(data?.user as UserType);
  const {
    title,
    paragraph,
    hasContent,
  } = useDocumentDescription(data?.text);

  const handlePress = useCallback(() => {
    if (!author) {
      return;
    }
    handleAuthorPress?.(author);
  }, [author]);

  const privateIconRender = useMemo(() => {
    if (!data?.private) {
      return null;
    }
    return (
      <Typography component="span" fontSize={16}>
        <Icon size="sm" marginRight={1} weight="duotone" color="icon" name="lock" />
      </Typography>
    );
  }, [data?.private]);

  return (
    <CardContent>
      <Box display="flex" flexDirection="column" gap={1}>
        {hasContent && title && (
          <Typography fontSize={16} fontWeight={600}>
            {privateIconRender}
            {title}
          </Typography>
        )}
        {hasContent && paragraph && (
          <Typography
            sx={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 8,
              overflow: 'hidden',
            }}
          >
            {!title && privateIconRender}
            {paragraph}
          </Typography>
        )}
        {!hasContent && (
          <Typography fontSize={16}>
            {privateIconRender}
            <Typography component="span" fontWeight={400} color="neutral" sx={{ color: 'var(--joy-palette-warning-solidDisabledColor)' }}>Empty document</Typography>
          </Typography>
        )}
        {hasAuthor && !!author && (
          <AuthorBlock onPress={handlePress} data={author} />
        )}
      </Box>
    </CardContent>
  );
};

export default memo(Note, (prevProps, nextProps) => isEqual(prevProps, nextProps));
