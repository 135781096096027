import type { DefaultRootState } from 'react-redux';

import type { ResourceVariant } from 'app/entities';

export const selectLoadNextCursor = (reload: boolean) => (state: DefaultRootState): string | null => {
  const { comment } = state;
  const nextCursor = comment?.list?.paginationInfo?.nextCursor as string | null | undefined;
  if (reload || !nextCursor) {
    return null;
  }
  return nextCursor;
};

export const comment = (id: number) => (state: DefaultRootState) => state.comment.data[id];

export const items = (
  resource?: ResourceVariant,
  resourceId?: number | null | undefined,
) => (state: DefaultRootState) => {
  const isAvailable = resource && resourceId && state.comment.meta.parent.resource === resource && state.comment.meta.parent.resourceId === resourceId;
  return isAvailable ? state.comment.list.sequence.map((item) => (state.comment.data[item.id])) : [];
};

export const listMeta = (
  resource?: ResourceVariant,
  resourceId?: number | null | undefined,
) => (state: DefaultRootState) => {
  if (state.comment.meta.parent.resource !== resource || state.comment.meta.parent.resourceId !== resourceId) {
    return {
      firstPage: {
        isLoading: false,
        isLoaded: false,
      },
      nextPage: {
        isLoading: false,
        isLoaded: false,
      },
    };
  }
  return {
    firstPage: {
      isLoading: state.comment.meta.isFirstPageLoading,
      isLoaded: state.comment.meta.isFirstPageLoaded,
    },
    nextPage: {
      isLoading: state.comment.meta.isNextPageLoading,
      isLoaded: state.comment.meta.isNextPageLoaded,
    },
  };
};

export const list = (
  resource?: ResourceVariant,
  resourceId?: number | null | undefined,
) => (state: DefaultRootState) => {
  const isAvailable = resource && resourceId && state.comment.meta.parent.resource === resource && state.comment.meta.parent.resourceId === resourceId;
  return {
    sequence: isAvailable ? state.comment.list.sequence : [],
    paginationInfo: isAvailable ? state.comment.list.paginationInfo : {
      hasNext: false,
      nextCursor: null,
    },
  };
};

export const reply = (state: DefaultRootState) => {
  const key = state.comment.input.currentReplyKey || '';
  const { id } = state.comment.reply[key] || {};
  if (!key || !id || !state.comment.data[id]) {
    return null;
  }
  return {
    id,
    type: 'reply',
    data: {
      ...state.comment.data[id],
    },
  };
};

export const edit = (state: DefaultRootState) => {
  const key = state.comment.input.currentEditKey || '';
  const { id, text } = state.comment.edit[key] || {};
  if (!key || !id || !state.comment.data[id]) {
    return null;
  }
  return {
    id,
    type: 'edit',
    text,
    data: {
      ...state.comment.data[id],
    },
  };
};

export const summary = (resource: ResourceVariant, resourceId: number | null | undefined) => (state: DefaultRootState) => ({
  data: state.comment.summary.data?.[`${resource}-${resourceId}`],
  isLoading: state.comment.summary.meta?.loadingMap?.[`${resource}-${resourceId}`] || false,
});

export const isLoadingNew = (resource?: ResourceVariant, resourceId?: number | string | null | undefined, id?: number | string) => (state: DefaultRootState) => (
  Boolean(state?.comment.meta.loadingNewMap?.[`${resource}-${resourceId}-${id}`])
);

export const isLoadingEdit = (resource?: ResourceVariant, resourceId?: number | null | undefined, id?: number | string) => (state: DefaultRootState) => (
  Boolean(state?.comment.meta.loadingNewMap?.[`${resource}-${resourceId}-${id}`])
);

export const isLoadingDelete = (resource?: ResourceVariant, resourceId?: number | null | undefined, id?: number | string) => (state: DefaultRootState) => (
  Boolean(state?.comment.meta.loadingNewMap?.[`${resource}-${resourceId}-${id}`])
);
