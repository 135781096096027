import {
  type FC, memo,
} from 'react';
import { useSelector } from 'react-redux';

import * as noteStore from 'store/nodes/note';

import TextStyles from './TextStyles';

type EditorProps = {
  id: number,
  contentWrapperClassName?: string,
}

const Editor: FC<EditorProps> = (props) => {
  const {
    id,
    contentWrapperClassName,
  } = props;

  const data = useSelector(noteStore.selectors.dataById(id));

  return (
    <TextStyles contentWrapperClassName={contentWrapperClassName}>
      <div className={contentWrapperClassName} dangerouslySetInnerHTML={{ __html: data?.text || '' }} />
    </TextStyles>
  );
};

export default memo(Editor);
