import {
  type ReactElement, type ReactNode,
  memo, useState, useEffect, useCallback,
} from 'react';
import {
  StyleSheet,
} from 'react-native';
import { useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { Box, useTheme } from '@mui/joy';

import { useDispatcher } from 'store/utils/redux/hooks';
import * as menuStore from 'store/nodes/menu';

import { useNavigate, useRoute } from 'navigation/hooks';

import { unit } from 'utils';
import { View, BlurView } from 'components/Themed';
import SearchInput from 'components/SearchInput';
import ScreenFiltersDropdown from 'components/ScreenFiltersDropdown';
import CopilotInput from 'widgets/Copilot/ui/CopilotInput';

import UserMenu from '../elements/UserMenu';

type TopBarProps = {
  hasSearchPanel: boolean,
  onSearchChange?: (value: string) => void,
  headerLeftPanel: ReactNode | 'search' | 'ask' | false,
  headerMiddlePanel: ReactNode | false,
  headerRightPanel: ReactNode | false,
};

const TopBar = (props: TopBarProps): ReactElement => {
  const {
    hasSearchPanel,
    onSearchChange,
    headerLeftPanel,
    headerMiddlePanel,
    headerRightPanel,
  } = props;

  const dispatcher = useDispatcher();
  const route = useRoute();
  const navigate = useNavigate();
  const theme = useTheme();

  const leftBarWidth = useSelector(menuStore.selectors.leftBarWidth);

  const [searchText, setSearchText] = useState('');

  const classes = useStyles();

  useEffect(() => {
    if (!route.isScreen('Ask')) {
      setSearchText('');
    }
  }, []);

  useEffect(() => {
    onSearchChange?.(searchText);
  }, [searchText]);

  const handleCopilotChange = useCallback((query: string) => {
    dispatcher.ask.setQuery({ query });
    if (!route.isScreen('Ask')) {
      navigate('Ask');
    }
  }, [route]);

  return (
    <BlurView factor={8} className={classes.container}>
      <Box
        position="absolute"
        top={0}
        right={0}
        bottom={0}
        left={0}
        bgcolor={theme.palette.background.body}
        sx={{
          opacity: 0.5,
        }}
      />
      <View style={styles.wrapper}>
        {headerLeftPanel !== false && (
          <>
            {!!headerLeftPanel && headerLeftPanel !== 'search' && headerLeftPanel !== 'ask' && (
              <View style={!headerMiddlePanel ? styles.longPanel : styles.leftPanel}>
                {headerLeftPanel}
              </View>
            )}
            {(!headerLeftPanel) && (
              <View style={!headerMiddlePanel ? styles.longPanel : styles.searchPanel}>
                {hasSearchPanel && (
                  <>
                    <SearchInput size="medium" />
                    <ScreenFiltersDropdown style={styles.filterButton} />
                  </>
                )}
              </View>
            )}
            {headerLeftPanel === 'search' && (
              <View style={!headerMiddlePanel ? styles.longPanel : styles.searchPanel}>
                <SearchInput size="medium" autoFocus={route.isScreen('Ask')} />
                <ScreenFiltersDropdown style={styles.filterButton} />
              </View>
            )}
            {headerLeftPanel === 'ask' && (
              <View style={!headerMiddlePanel ? styles.longPanel : styles.searchPanel}>
                <Box flex={1} display="flex" flexDirection="column" alignItems="stretch">
                  <CopilotInput
                    size="small"
                    focusBorderSize="0.0666em"
                    onChange={handleCopilotChange}
                    multiline={false}
                  />
                </Box>
              </View>
            )}
          </>
        )}
        {!!headerMiddlePanel && (
          <View style={styles.middlePanel}>
            {headerMiddlePanel}
          </View>
        )}
        {headerRightPanel !== false && (
          <View style={styles.rightPanel}>
            {!!headerRightPanel && (
              headerRightPanel
            )}
            {!headerRightPanel && (
              <UserMenu />
            )}
          </View>
        )}
      </View>
    </BlurView>
  );
};

const useStyles = createUseStyles({
  container: {
    '&': {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      paddingLeft: 'var(--menu-bar-width)',
      paddingRight: 0,
    },
    '.menu-collapsed &': {
      paddingLeft: 52,
      paddingRight: 52,
      '@media (max-width: 1384px) and (min-width: 1281px)': {
        paddingRight: 'calc(((100vw - 1280px) / 104)* 52)',
      },
      '@media (max-width: 1280px)': {
        paddingRight: 0,
      },
    },
    '@media (max-width: 1384px) and (min-width: 1281px)': {
      paddingRight: 0,
    },
    '@media (max-width: 1280px)': {
      paddingRight: 0,
    },
  },
});

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    height: unit(80),
    width: '100%',
    maxWidth: unit(1280),
    paddingHorizontal: unit(16),
    alignItems: 'center',
    alignSelf: 'center',
  },
  logoButton: {
    marginRight: unit(40),
  },
  longPanel: {
    flex: 1,
    marginRight: unit(24),
    alignItems: 'center',
    flexDirection: 'row',
  },
  searchPanel: {
    marginRight: unit(24),
    width: unit(320),
  },
  searchInput: {
    maxHeight: unit(14),
  },
  leftPanel: {
    alignItems: 'flex-start',
  },
  filterButton: {
    marginLeft: unit(16),
  },
  middlePanel: {
    flex: 1,
    alignItems: 'flex-start',
  },
  rightPanel: {
    alignItems: 'flex-end',
  },
});

export default memo(TopBar);
